import React, { useState } from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SelectBtn, SignupContainer } from 'src/Components';

export default function AvailabilityStep() {
  const navigate = useNavigate();

  const {
    levels,
  } = useSelector((state) => state.Level);

  const [selectIndex, setSelectIndex] = useState('')

  const onSelectLevel = (index) => {
    setSelectIndex(index);
    setTimeout(() => {
      navigate('/sign-up/step-2');
    }, 500);
  };

  return (
    <div>
      Tutor Signup Step1
    </div>
  );
}
import React, { useState } from 'react';
import './style.scss';
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormInput, SignupContainer, SelectBtn } from 'src/Components';
import SearchIcon from "src/Assets/Images/Common/search-icon.svg";
import { setStudentInfo } from 'src/Redux/Actions';

export default function SubjectStep() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    subjects,
  } = useSelector((state) => state.Subject);

  const {
    studentInfo,
  } = useSelector((state) => state.Student);

  const [searchText, setSearchText] = useState('');
  const [filterSubjects, setFilterSubjects] = useState([]);
  const [selectIndex, setSelectIndex] = useState('')

  const onChangeText = (text) => {
    setSearchText(text);
    const _filterSubjects = subjects.filter(
      item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1,
    );
    setFilterSubjects(_filterSubjects);
  };

  const onSelectSubject = (index) => {
    const subject_id = subjects[index].id;
    dispatch(setStudentInfo({
      ...studentInfo,
      subject_id,
      tutoring_need: `Student need help with ${subjects[index].name}`
    }));
    setSelectIndex(index);
    setTimeout(() => {
      navigate('/sign-up/step-5');
    }, 500);
  };

  return (
    <SignupContainer
      step={4}
      title="Which math do you need help with?"
      className='signup-step4'
    >
      <div className='step4-content mx-auto'>
        <FormInput
          placeholder={"Search Subject"}
          className="first-name"
          icon={SearchIcon}
          value={searchText}
          onChange={e => onChangeText(e)}
        />

        <div className='drop-content'>
          {!_.isEmpty(filterSubjects) && (
            filterSubjects.map((item, index) => (
              <SelectBtn
                key={index}
                className='select-btn'
                title={item.name}
                index={index}
                selectIndex={selectIndex}
                onClick={onSelectSubject}
              />
            ))
          )}
        </div>
      </div>
    </SignupContainer>
  );
}
import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import blogHeaderBG from 'src/Assets/Images/Blog/header-bg.png'
import { EmailValidation } from 'src/Utils/Validation';
import { mailChimpAction } from 'src/Redux/Actions';
import { hideSpinner, showSpinner } from 'src/Utils/Helper';

export default function BlogStartArea() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getLoadingRef = useRef(false);
  const {
    mailChimpStatus, mailChimp_success, mailChimp_loading
  } = useSelector(
    state => state.MailChimp,
  );

  const [mail, setMail] = useState('');
  const [mailValidation, setMailValidation] = useState(null);

  useEffect(() => {
    if (getLoadingRef.current && !mailChimp_loading) {
      hideSpinner();
      if (mailChimp_success)
        navigate('/sign-up/step-1');
    }
    getLoadingRef.current = mailChimp_loading;
  }, [dispatch, mailChimpStatus, mailChimp_loading, mailChimp_success, navigate]);

  const changeMail = (value) => {
    setMail(value);
    setMailValidation(EmailValidation(mail));
  };

  const navigateToSignup = () => {
    setMailValidation(EmailValidation(mail));
    if (EmailValidation(mail)) {
      showSpinner();
      dispatch(mailChimpAction({ mail }));
    };
  };

  return (
    <div className="blogstartarea-component">
      <img src={blogHeaderBG} alt="right-bg" className="blog-header-bg show-web" />
      <div className="container blogstartarea-container">
        <div className="col-lg-7 left-area">
          <h1>The latest on for all things educations</h1>
          <div className="form-area">
            <div className="email-area">
              <p>Subscribe and get our weekly newsletter in your index.</p>
              <div className="send-box" >
                <input
                  className={`email-input ${mailValidation === false ? "error-input" : ""}`}
                  placeholder="Enter your Email"
                  value={mail}
                  onChange={(event) => changeMail(event.target.value)}
                />
                <button className="send-btn" onClick={navigateToSignup}>Get Started</button>
              </div>
            </div>
          </div>
          <img src={blogHeaderBG} alt="right-bg" className="blog-header-bg show-mobile" />
        </div>
      </div>
    </div>
  );
}
import React from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg'
import { SeeOnInfo } from './seeonInfo';

export default function AsSeenOn() {

  return (
    <div className="seen-on landing-part">
      <div className="">
        <h2>As seen on</h2>
        <div className='show-web'>
          <div className="first-row">
            {SeeOnInfo.map((item, index) => (
              index < 5 && (
                <div className='item-content center' key={index}>
                  <ReactSVG src={item.logo} />
                </div>
              )
            ))}
          </div>
          <div className="second-row">
            {SeeOnInfo.map((item, index) => (
              index > 4 && (
                <div className='item-content center' key={index}>
                  <ReactSVG src={item.logo} />
                </div>
              )
            ))}
          </div>
        </div>

        <div className='show-mobile'>
          <div className="row">
            {SeeOnInfo.map((item, index) => (
              <div className='item-content center col-lg-6' key={index}>
                <ReactSVG src={item.logo} className='logo' />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
import {
  GET_BRAINTREE_KEY,
  NONCE_UPDATE,
  FIRST_CHARGE,
} from '../Type';

export const getBraintreeKey = () => {
  return {
    type: GET_BRAINTREE_KEY,
    data: {}
  }
}

export const nonceUpdate = (data) => {
  return {
    type: NONCE_UPDATE,
    data
  }
}

export const firstCharge = () => {
  return {
    type: FIRST_CHARGE,
    data: {}
  }
}
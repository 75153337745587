import React, { useState } from "react";
import './style.scss';
import arrowDown from 'src/Assets/Images/Landing/Arrow-down.png';
import schoolLevelIcon from 'src/Assets/Images/Landing/school-level.png';
import SubjectAccordion from "../SubjectAccordion";

export default function LevelAccordion({subPages, level, className}) {

    const [isOpen, setIsOpen] = useState(false)

    const toggleAccordion = () => setIsOpen(!isOpen)

    return (
        <div className={`level-accordion ${isOpen ? 'open' : ''} ${className}`}>
            <div className="header-content" onClick={toggleAccordion}>
                <img src={`${process.env.REACT_APP_IMAGE_DOMAIN}${subPages[0].level.icon}`} className="level-icon"/>
                <div className="info">
                    <h6>{level}</h6>
                    <p className="mb-0">{subPages[0].level.sub_title}</p>
                </div>
                <img src={arrowDown} className="arrow-down"/>
            </div>
            <div className={`level-content ${isOpen ? 'show' : ''}`}>
                {
                    subPages.map((item, index) => (
                        <SubjectAccordion page={item} key={index} className={className}/>
                    ))
                }
            </div>
        </div>
    )
}
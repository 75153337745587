import { GET_START_DATE, GET_START_DATE_SUCCESS, GET_START_DATE_FAIL } from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import { getStartDates as getStartDatesApi } from 'src/Api';

function* getStartDates() {
  try {
    const result = yield getStartDatesApi();
    if (result) {
      yield put({ type: GET_START_DATE_SUCCESS, data: result });
    } else {
      yield put({ type: GET_START_DATE_FAIL, data: [] });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetStartDates() {
  yield takeLatest(GET_START_DATE, getStartDates);
}

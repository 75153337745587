import { GET_PLAN, GET_PLAN_SUCCESS, GET_PLAN_FAIL } from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import { getPlan as getPlanApi } from 'src/Api';

function* getPlan() {
  try {
    const result = yield getPlanApi();
    if (result.data) {
      yield put({ type: GET_PLAN_SUCCESS, data: result.data });
    } else {
      yield put({ type: GET_PLAN_FAIL, data: [] });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetPlan() {
  yield takeLatest(GET_PLAN, getPlan);
}

import {
  SIGN_UP_HUBSPOT,
  SIGN_UP_HUBSPOT_SUCCESS,
  SIGN_UP_HUBSPOT_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
  signUpHubSpot as signUpHubSpotApi,
} from 'src/Api';

/**
 * Login
*/
function* signUpHubSpot(payload) {
  try {
    const result = yield signUpHubSpotApi(payload.data);
    if (result && result.token) {
      yield put({ type: SIGN_UP_HUBSPOT_SUCCESS, data: result });
    } else {
      yield put({ type: SIGN_UP_HUBSPOT_FAIL, data: result });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchSignUpHubSpot() {
  yield takeLatest(SIGN_UP_HUBSPOT, signUpHubSpot);
}

import React from 'react';
import './style.scss';
import { faqs } from './faqs';
import { Accordion } from 'src/Components';

export default function FAQsPage() {

  return (
    <div className="faqs-page">
      <div className="container faqs-container">
        <div className="title">FAQs</div>
        <div className="faqs-list shadow-object">
          {faqs.map((item, index) => {
            return (
              <Accordion
                className="accordion"
                title={item.title}
                content={item.content}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
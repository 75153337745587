import React from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg'
import { BrandList } from './brand';

export default function Universities({
  className = '',
  subject = 'MATH'
}) {

  return (
    <div className={`tutors-from ${className}`}>
      <div className="container">
        <h3 className='text-center'>ONLINE {subject} TUTORS FROM</h3>
        <div className='brand-content show-web-flex v-c'>
          {BrandList.map((item, index) => (
            <ReactSVG src={item} className="brand" key={index} />
          ))}
        </div>
        <div className='brand-content show-mobile-flex v-c'>
          {BrandList.map((item, index) => (
            index < 3 && <ReactSVG src={item} className="brand" key={index} />
          ))}
        </div>
        <div className='brand-content show-mobile-flex v-c'>
          {BrandList.map((item, index) => (
            index > 2 && <ReactSVG src={item} className="brand" key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}
import { Routes, Route } from 'react-router-dom';
import { SignInPage, ForgotPassPage, NewPassPage } from 'src/Pages';

function AuthRoutes() {
  return (
    <div>
      <div className='main-content'>
        <Routes>
          <Route exact path="/sign-in" element={<SignInPage />} />
          <Route exact path="/forgot-password" element={<ForgotPassPage />} />
          <Route exact path="/new-password" element={<NewPassPage />} />
        </Routes>
      </div>
    </div>
  );
}

export default AuthRoutes;

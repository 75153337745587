import { all, fork } from 'redux-saga/effects';
import { watchMailChimpAction } from './MailChimp';
import { watchSignUpHubSpot } from './HubSpot';
import { watchGetBlogs, watchGetSubjectBlogs } from './Blogs';
import { watchGetAllReviews } from './Review';
import { watchGetPlan } from './Plan';
import { watchGetLevels } from './Level';
import { watchGetSubjects } from './Subject';
import { watchGetFrequency } from './Frequency';
import { watchGetStartDates } from './StartDate';
import { watchGetChannelDetail, watchGetPages, watchGetSubjectPage, watchGetTutors, watchGetTvData } from './Core';
import {
  watchSignIn,
  watchForgotPassword,
  watchStudentSignUP,
  watchTutorSignUP,
  watchProfileUpdate,
} from './Auth';
import {
  watchGetBraintreeKey,
  watchNonceUpdate,
  watchFirstCharge,
} from './Payment';

export default function* rootSaga() {
  yield all([
    fork(watchMailChimpAction),
    fork(watchSignUpHubSpot),
    fork(watchGetBlogs),
    fork(watchGetAllReviews),
    fork(watchGetPlan),
    fork(watchGetLevels),
    fork(watchGetSubjects),
    fork(watchGetFrequency),
    fork(watchGetStartDates),
    fork(watchSignIn),
    fork(watchForgotPassword),
    fork(watchStudentSignUP),
    fork(watchTutorSignUP),
    fork(watchProfileUpdate),
    fork(watchGetBraintreeKey),
    fork(watchNonceUpdate),
    fork(watchFirstCharge),
    fork(watchGetTvData),
    fork(watchGetChannelDetail),
    fork(watchGetTutors),
    fork(watchGetPages),
    fork(watchGetSubjectPage),
    fork(watchGetSubjectBlogs)
  ]);
}

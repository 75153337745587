import React from "react";
import './style.scss';
import ContentTable from "../ContentTable";

export default function ExampleTutorSession({page}) {

    return (
        <div className="example-tutor-session landing-part" id="sub-page-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-8">
                        <h3>{page.tutor_session_title}</h3>
                        <video width="100%" height="90%"
                            key={1}
                            controls>
                            <source
                                src={`${page.tutor_session_video}#t=0.01`}
                                type="video/webm"
                            />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
    )
}
import { Api } from './Api';

export function* getBraintreeKey() {
  const jsonData = yield Api.get("/payments/braintreekey");
  return jsonData;
}

export function* nonceUpdate(data) {
  const jsonData = yield Api.formPost("/payments/braintreecard", data);
  return jsonData;
}

export function* firstCharge() {
  const jsonData = yield Api.post("/client_charge_first_time");
  return jsonData;
}
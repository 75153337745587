import React from 'react';
import './style.scss';
import { useSelector } from 'react-redux';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import { SignupContainer } from 'src/Components';
import { GetDashboardUrl } from 'src/Constant';
import newClient from "src/Assets/Videos/newClient.mp4";

export default function FinalVideoStep() {
  const {
    user, token
  } = useSelector(
    state => state.Auth,
  );

  const onNext = () => {
    if (user && token) {
      window.location.href = GetDashboardUrl(user, token);
    }
  };

  return (
    <SignupContainer
      step={7}
      title="What is next?"
      className='signup-step7'
    >
      <div>
        <div className='video-play-content'>
          <Player autoPlay
            fluid={false}
            width='100%'
            height='100%'
          >
            <source src={newClient} />
          </Player>
        </div>
        <div className='done-btn btn-2' onClick={onNext}>GO TO ACCOUNT</div>
      </div>
    </SignupContainer>
  );
}
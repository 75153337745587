import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EmailValidation } from 'src/Utils/Validation';
import { mailChimpAction } from 'src/Redux/Actions';
import { hideSpinner, showSpinner } from 'src/Utils/Helper';

export default function BlogFooter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getLoadingRef = useRef(false);
  const {
    mailChimpStatus, mailChimp_success, mailChimp_loading
  } = useSelector(
    state => state.MailChimp,
  );

  const [mail, setMail] = useState('');
  const [mailValidation, setMailValidation] = useState(null);

  useEffect(() => {
    if (getLoadingRef.current && !mailChimp_loading) {
      hideSpinner();
      if (mailChimp_success)
        navigate('/sign-up/step-1');
    }
    getLoadingRef.current = mailChimp_loading;
  }, [dispatch, mailChimpStatus, mailChimp_loading, mailChimp_success, navigate]);

  const changeMail = (value) => {
    setMail(value);
    setMailValidation(EmailValidation(mail));
  };

  const navigateToSignup = () => {
    setMailValidation(EmailValidation(mail));
    if (EmailValidation(mail)) {
      showSpinner();
      dispatch(mailChimpAction({ mail }));
    };
  };

  return (
    <div className="blog-footer-component">
      <div className="container blog-footer-container">
        <div className="row main-row">
          <div className="col-lg-6 left-area">
            <h1>No contract.</h1>
            <h1>No minimum commitment.</h1>
            {/* <img src={footerPrice} className="show-mobile" alt="footer-price" /> */}
            <p>High Dosage 1-on-1 Online Math Tutoring</p>
            <div className="form-area">
              <div className="email-area">
                <div className="send-box">
                  <input
                    className={`email-input ${mailValidation === false ? "error-input" : ""}`}
                    placeholder="Enter your Email"
                    value={mail}
                    onChange={(event) => changeMail(event.target.value)}
                  />
                  <button className="send-btn" onClick={navigateToSignup}>Get Started</button>
                </div>
                <div className="explain">Over 10,00 verified and checked US-based tutors ready to work with you!</div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 right-area show-web-flex">
            {/* <img src={blogRight} alt="right-bg" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
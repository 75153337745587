import React from "react";
import LevelAccordion from "./LevelAccordion";

export default function OnlineTutorServices({pages}) {

    const groupedPages = pages.reduce((group, item) => {
        const { level } = item
        group[level.name] = group[level.name] ?? []
        group[level.name].push(item)
        return group
      }, {})

    return (
        <div className="online-tutor-services landing-part">
            <div className="container">
                <h2>Online Math Tutoring Services</h2>
                <div className="row mt-5 w-100">
                        {
                            Object.keys(groupedPages).map((item, index) => (
                                <div className="col-lg-4 tutor-sec mb-3" key={index}>
                                    <LevelAccordion subPages={groupedPages[item]} level={item} />
                                </div>
                            ))
                        }
                </div>
            </div>
        </div>
    )
}
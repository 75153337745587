import { GET_BLOGS, GET_SUBJECT_BLOGS } from '../Type';

export const getBlogs = () => {
  return {
    type: GET_BLOGS,
    data: {}
  }
}

export const getSubjectBlogsAction = (subject_id) => {
  return {
    type: GET_SUBJECT_BLOGS,
    subject_id
  }
}
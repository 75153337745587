import React, { useEffect } from 'react';
import './style.scss';
import {
  HomeStart,
  Universities,
  ServiceTimes,
  HowItWorks,
  AboutOurTutors,
  Tutors,
  LeadMathTutor,
  FAQs,
  PricingSection,
  Reviews,
  HowTutor,
  // Stories,
  Blog,
  Services,
  AsSeenOn,
  TopRatedTutors,
  FreeTestPreps,
  OnlineTutorServices,
} from 'src/Components';
import { useDispatch, useSelector } from 'react-redux';
import { getPagesAction } from 'src/Redux/Actions';
import _ from 'lodash';
import { GET_PAGES_SUCCESS } from 'src/Redux/Type';

export default function LandingPage() {
  const dispatch = useDispatch()

  const { 
    tvData,
    tutors,
    pages
   } = useSelector(state => state.Core)

   const {
    blogs,
  } = useSelector((state) => state.Blogs);

   useEffect(() => {
    const cached_pages = JSON.parse(sessionStorage.getItem('subject_pages'))
    if(_.isEmpty(cached_pages)) {
      dispatch(getPagesAction())
    } else dispatch({type: GET_PAGES_SUCCESS, data: cached_pages })
   },[])
  
  return (
    <div className="landing-page">
      <HomeStart />
      <Universities />
      <ServiceTimes />
      { tutors && <TopRatedTutors itemCount={4} tutors={tutors}/>}
      { tvData && <FreeTestPreps tvData={tvData}/>}
      <HowItWorks />
      <AboutOurTutors />
      {/* <Tutors /> */}
      <Reviews />
      <HowTutor />
      <LeadMathTutor />
      { pages && <OnlineTutorServices pages={pages}/>}
      <FAQs />
      <PricingSection />
      {/* <Stories /> */}
      { blogs && <Blog blogs={blogs}/> }
      {/* <Services /> */}
      <AsSeenOn />
    </div>
  );
}
import React from "react";
import './style.scss';
import bank from '../../../../Assets/Images/Common/bank.png';

export default function TutorCard({className, tutor, team}) {
    
    return (
         <div className={`tutor-component ${className}`}>
            <div className="bg"></div>
            <div className="info-area">
                <img src={`${process.env.REACT_APP_IMAGE_DOMAIN}${tutor.picture}`} alt="avatar" className="sub-info tutor-avatar" />
            </div>
            <div className="info-area">
                <div className={`${team ? "team-sub-info team-tutor-info" : "sub-info tutor-info"}`}>
                    <div className="name">{tutor.name}</div>
                    <div className="sub-text">{tutor.profile_title}</div>
                    {(tutor.school && tutor.school.length > 0) && 
                        <div className="school">
                            <img src={bank} alt="bank" />
                            <div className="sub-text">{tutor.school}</div>
                        </div>
                    }
                    {(tutor.about_me && tutor.about_me.length > 0) && 
                        <div className={"tutor-description"}>{tutor.about_me}</div>
                    }
                    <div className="try-btn">TRY ME FOR $1</div>
                </div>
            </div>
            <div className="bg-dot custom-dot" alt="bgdot" />
        </div>
    )
}
import { MAIL_CHIMP_ACTION, MAIL_CHIMP_ACTION_SUCCESS, MAIL_CHIMP_ACTION_FAIL } from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import { mailChimpAction as mailChimpActionApi } from 'src/Api';

function* mailChimpAction(payload) {
  try {
    const result = yield mailChimpActionApi(payload.data);
    console.log("result", result)
    if (result) {
      yield put({ type: MAIL_CHIMP_ACTION_SUCCESS, data: result });
    } else {
      yield put({ type: MAIL_CHIMP_ACTION_FAIL, data: result });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchMailChimpAction() {
  yield takeLatest(MAIL_CHIMP_ACTION, mailChimpAction);
}

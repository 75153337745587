import React from 'react';
import './style.scss';
import StarRatings from 'react-star-ratings';
import { useDispatch, useSelector } from 'react-redux';
import { StarSVGViewBox, StarSVGIconPath } from '../../../Constant';
// import moment from "moment";
import { toggleReviewModal } from 'src/Redux/Actions';

function innerFunc(description) {
  return { __html: description }
}

export default function Review({
  info, index, className = ''
}) {
  const dispatch = useDispatch();
  const {
    isReviewModal
  } = useSelector((state) => state.Modal);

  const {
    tutor: { name, location },
    rating1,
    rating2,
    review_content,
    reviewed_on
  } = info

  const onReadMore = () => dispatch(toggleReviewModal(true, {...info, index}));

  return (
    <div className={`review-component v-r round-object ${className}`}>
      <div className="review-header">
        <div className={`avatar v-r h-c v-c user-${index % 3}`}>
          {(name.split(' ')[0]).charAt(0)}{name.split(' ').length > 1 ? (name.split(' ')[1]).charAt(0) : ''}
        </div>
        <div className="reviewer-info">
          <div>
            <div className='name'>
              {name}
              <span className='address'>{` from ${location}`}</span>
            </div>
          </div>
          <div className="star-area">
            <div className="v-c">
              <span>Knowledge: </span>
              <StarRatings
                rating={Number(rating1)}
                starDimension="17px"
                starSpacing="2.14px"
                starRatedColor="#FBA92E"
                starEmptyColor="rgba(48, 42, 50, 0.1)"
                svgIconViewBox={StarSVGViewBox}
                svgIconPath={StarSVGIconPath}
              />
            </div>
            <div className="v-c">
              <span>Presentation: </span>
              <StarRatings
                rating={Number(rating2)}
                starDimension="17px"
                starSpacing="2.14px"
                starRatedColor="#FBA92E"
                starEmptyColor="rgba(48, 42, 50, 0.1)"
                svgIconViewBox={StarSVGViewBox}
                svgIconPath={StarSVGIconPath}
              />
            </div>
          </div>
        </div>
      </div>
      <p className="review" dangerouslySetInnerHTML={innerFunc(review_content)} />
      {/* <span>{moment(reviewed_on).format('ll')}</span> */}
      {!isReviewModal && <p className="continue-btn" onClick={onReadMore}>Read More</p>}
    </div>
  );
}
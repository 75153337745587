import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormInput, SignupContainer } from 'src/Components';
import {
  NameValidation,
  EmailValidation,
  PhoneValidation,
  PasswordValidation
} from 'src/Utils/Validation';
import { studentSignUP, signUpHubSpot } from 'src/Redux/Actions';
import { showSpinner, hideSpinner, showToast } from 'src/Utils/Helper';

export default function AccountStep() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getLoadingRef = useRef(false);
  const {
    user, token, signUp_res, signUp_success, signUp_loading
  } = useSelector(
    state => state.Auth,
  );

  const {
    studentInfo,
  } = useSelector((state) => state.Student);

  const [userInfo, setUserInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone: '',
    referrer_code: '',
    send_alert: "not"
  });

  const [userValidation, setUserValidation] = useState({
    firstNameValid: '',
    lastNameValid: '',
    emailValid: '',
    passwordValid: '',
    phoneValid: '',
  });

  useEffect(() => {
    if (getLoadingRef.current && !signUp_loading) {
      hideSpinner();
      if (signUp_success) {
        const payload = {
          ...studentInfo,
          ...userInfo
        };
        dispatch(signUpHubSpot(payload));
        // showToast('success', 3000, signUp_res);
        navigate('/sign-up/step-6');
      } else {
        showToast('error', 3000, signUp_res);
      };
    }
    getLoadingRef.current = signUp_loading;
  }, [dispatch, signUp_loading, signUp_success, navigate, signUp_res, user, token, studentInfo, userInfo]);

  const onChangeUser = (field, validationField, value) => {
    setUserInfo({
      ...userInfo,
      [field]: value,
    });
    if (validationField !== 'referrerCodeValid') {
      setUserValidation({
        ...userValidation,
        [validationField]: getValidation(field, value)
      });
    }
  };

  const getValidation = (field, value) => {
    if (field === 'first_name' || field === 'last_name')
      return NameValidation(value);
    if (field === 'email') return EmailValidation(value);
    if (field === 'phone') return PhoneValidation(value);
    return PasswordValidation(value);
  };

  const checkDisableBtn = () => {
    if (
      NameValidation(userInfo.first_name) &&
      NameValidation(userInfo.last_name) &&
      EmailValidation(userInfo.email) &&
      PasswordValidation(userInfo.password) &&
      PhoneValidation(userInfo.phone)
    )
      return false;
    return true;
  }

  const onDone = () => {
    if (!checkDisableBtn()) {
      const payload = {
        ...studentInfo,
        ...userInfo
      }
      showSpinner();
      dispatch(studentSignUP(payload));
    }
  };

  return (
    <SignupContainer
      step={5}
      title="Let's make better grades together"
      className='signup-step5'
    >
      <div className='sub-title text-center'>
        Trusted by over 10,000 families.
        <strong>1st lesson is only $1</strong>
      </div>
      <div className='row pv-3'>
        <div className='col-lg-6 col-md-6 col-sm-12 col-12' >
          <FormInput
            placeholder={"First Name"}
            className="first-name"
            validation={userValidation.firstNameValid}
            value={userInfo.first_name}
            onChange={e => onChangeUser('first_name', 'firstNameValid', e)}
          />
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 col-12' >
          <FormInput
            placeholder={"Last Name"}
            value={userInfo.last_name}
            className="last_name"
            validation={userValidation.lastNameValid}
            onChange={e => onChangeUser('last_name', 'lastNameValid', e)}
          />
        </div>
      </div>
      <div className='col-lg-12 pv-3' >
        <FormInput
          placeholder={"Email"}
          value={userInfo.email}
          validation={userValidation.emailValid}
          onChange={e => onChangeUser('email', 'emailValid', e)}
        />
      </div>
      <div className='col-lg-12 pv-3' >
        <FormInput
          placeholder={"Password"}
          value={userInfo.password}
          type={'password'}
          validation={userValidation.passwordValid}
          onChange={e => onChangeUser('password', 'passwordValid', e)}
        />
      </div>
      <div className='col-lg-12 pv-3' >
        <FormInput
          placeholder={"Referral code"}
          value={userInfo.referrer_code}
          onChange={e => onChangeUser('referrer_code', 'referrerCodeValid', e)}
        />
      </div>
      <div className='col-lg-12 pv-3' >
        <FormInput
          placeholder={"Phone"}
          value={userInfo.phone}
          validation={userValidation.phoneValid}
          onChange={e => onChangeUser('phone', 'phoneValid', e)}
        />
      </div>
      <div className='policy-content text-center'>
        <span>
          {`By clicking ' Done", you agree with `}
          <a href="/" target="_blank">OnlineMathTutor.com</a>
          {` `}
          <a href="/privacy" target="_blank">Terms and Privacy.</a>
        </span>
      </div>
      <div className={`done-btn btn-2 ${checkDisableBtn() ? 'disable' : ''}`} onClick={onDone}>DONE</div>
      <div className='have-account text-center'>
        <span>
          {`Already have an account? `}
          <a href="/auth/sign-in" target="_blank">Sing In</a>
        </span>
      </div>
    </SignupContainer>
  );
}
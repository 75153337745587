import React from 'react';
import './style.scss';
import rightArrow from 'src/Assets/Images/Common/arrow-right.svg'

export default function SelectBtn({
  className = '', title = '', index, selectIndex, onClick,
}) {

  return (
    <div
      className={
        `select-btn 
          ${className}
          ${index === selectIndex ? 'active' : ''}
        `
      }
      onClick={() => onClick(index)}>
      <div className='title'>{title}</div>
      <img src={rightArrow} alt='arrow' className='arrow-btn' />
    </div >
  );
}
import React, { useState } from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg'
import add from 'src/Assets/Images/Landing/add.svg';
import _ from 'lodash';

export default function Accordion({
  className, title, content, icon, answer
}) {

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-component ${className}`}>
      <div className="title-area" onClick={() => toggleAccordion()}>
        <div className="accordion-title">{title}</div>
        <ReactSVG src={icon || add} className={`icon ${isOpen ? icon ? 'has-icon-close' : 'icon-close' : icon ? 'has-icon' : 'icon-open'}`} />
      </div>
      { _.isEmpty(answer) ? 
      content.map((item, index) => (
        <div className={`accordion-content ${isOpen ? 'active' : ''}`} key={index}>{item}</div>
      )) : <div className={`accordion-content ${isOpen ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: answer }}></div>}
    </div>
  );
}
import React, { useEffect, useState } from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SelectBtn, SignupContainer } from 'src/Components';
import { getSubjects, setStudentInfo } from 'src/Redux/Actions';

export default function LevelStep() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    levels,
  } = useSelector((state) => state.Level);

  const {
    studentInfo,
  } = useSelector((state) => state.Student);

  const [selectIndex, setSelectIndex] = useState('');

  useEffect(() => {
    if (studentInfo.level_id) {
      const _selectIndex =
        levels.findIndex(temp => temp.id === studentInfo.level_id);
      setSelectIndex(_selectIndex);
    }
  }, [levels, studentInfo.level_id]);

  const onSelectLevel = (index) => {
    const level_id = levels[index].id;
    dispatch(getSubjects(level_id));
    dispatch(setStudentInfo({ ...studentInfo, level_id }));
    setSelectIndex(index);
    setTimeout(() => {
      navigate('/sign-up/step-2');
    }, 500);
  };

  return (
    <SignupContainer
      step={1}
      title="What grade level is the student?"
      className='signup-step1'
    >
      <div className='row'>
        {levels.map((item, index) => (
          item.id < 7 &&
          <div className='col-lg-6 col-md-6 col-sm-6 col-6' key={index}>
            <SelectBtn
              title={item.name}
              index={index}
              selectIndex={selectIndex}
              onClick={onSelectLevel}
            />
          </div>
        ))}
      </div>
    </SignupContainer>
  );
}
import React from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Pricing, } from 'src/Components';

export default function PlanStep() {
  const navigate = useNavigate();

  const {
    currentPlan,
  } = useSelector((state) => state.Plan);

  const onNext = () => {
    navigate('/sign-up/payment-enroll');
  };

  return (
    <div className='plan-step-component'>
      <div className="container plan-step-container">
        <h2>Thanks For Completing Your Trial Lesson</h2>
        <h5>{`Select how many tutoring hours you need: ${currentPlan.name}`}</h5>
        <div className='header-note-content mx-auto'>
          Wow! You completed your free trial lesson with Peter Demo.
          Please select a tutoring plan to continue tutoring.
          You can always request a new tutor or cance vour olan at any time.
        </div>
        <Pricing
          onNext={onNext}
        />
      </div>
    </div>
  );
}
import React from 'react';
import './style.scss';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import left from "src/Assets/Images/Landing/Slider/arrow-left.svg";
import right from "src/Assets/Images/Landing/Slider/arrow-right.svg";

const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 36,
    width: 36,
    backgroundColor: '#0C7B60',
    // border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span>
        <img src={left} alt="arrow" />{" "}
      </span>
      <span style={{ width: 5 }}> </span>
      <span>
        <img src={right} alt="arrow" />{" "}
      </span>
    </SliderThumb>
  );
}

export default function PriceSlider({
  className, min, max, value, onChange
}) {

  return (
    <div className={`price-slider ${className}`}>
      <PrettoSlider
        valueLabelDisplay="on"
        aria-label="pretto slider"
        value={Number(value)}
        min={Number(min)}
        max={Number(max)}
        onChange={(e, value) => onChange(value)}
        slots={{ thumb: AirbnbThumbComponent }}
      />
    </div>
  );
}
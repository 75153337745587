import React from 'react';
import './style.scss';

export default function ServiceTimes() {

  return (
    <div className="service-times">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h3>
              OnlineMathTutor.com<span id="square" className='tm'>TM</span> Provide Unlimited Online Math Tutoring Support For K -12, College & Test Prep
            </h3>
          </div>
          <div className="col-lg-6 time-description v-c space-between">
            <div className='v-c'>
              <h2 className='color-1'>24</h2>
              <div className='unit'>{`HOURS \n A DAY`}</div>
            </div>
            <div className='v-c'>
              <h2 className='color-2'>7</h2>
              <div className='unit'>{`HOURS \n A WEEK`}</div>
            </div>
            <div className='v-c'>
              <h2 className='color-3'>365</h2>
              <div className='unit'>{`HOURS \n A YEAR`}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
import {
    GET_TV_DATA,
    GET_TV_DATA_FAIL,
    GET_TV_DATA_SUCCESS,
    GET_CHANNEL_DETAIL,
    GET_CHANNEL_DETAIL_FAIL,
    GET_CHANNEL_DETAIL_SUCCESS,
    SET_CURRENT_CHANNEL,
    GET_TUTORS,
    GET_TUTORS_FAIL,
    GET_TUTORS_SUCCESS,
    GET_PAGES_SUCCESS,
    GET_PAGES_ERROR,
    GET_PAGES,
    GET_SUBJECT_PAGE_SUCCESS,
    GET_SUBJECT_PAGE_ERROR,
    GET_SUBJECT_PAGE
} from '../Type';

const INITIAL = {
    tvData: null,
    channel: null,
    loadingChannel: false,
    tutors: null,
    pages: null,
    subject_page: null,
    getSubjectPage_loading: false,
    getPage_success: false
}

export default (state = INITIAL, action) => {
    switch(action.type) {
        case GET_TV_DATA: {
            return {
                ...state,
            }
        }
        case GET_TV_DATA_SUCCESS: {
            return {
                ...state,
                tvData: action.data
            }
        }
        case GET_TV_DATA_FAIL: {
            return {
                ...state,
                tvData: null
            }
        }
        case GET_CHANNEL_DETAIL: {
            return {
                ...state,
                loadingChannel: true
            }
        }
        case GET_CHANNEL_DETAIL_SUCCESS: {
            sessionStorage.setItem(`channel-${action.data.id}`, JSON.stringify(action.data))
            return {
                ...state,
                channel: action.data,
                loadingChannel: false
            }
        }
        case GET_CHANNEL_DETAIL_FAIL: {
            return {
                ...state,
                loadingChannel: false
            }
        }
        case SET_CURRENT_CHANNEL: {
            const { currentChannel } = action.data
            return {
                ...state,
                channel: currentChannel
            }
        }
        case GET_TUTORS: {
            return {
                ...state
            }
        }
        case GET_TUTORS_SUCCESS:  {
            return {
                ...state,
                tutors: action.data
            }
        }
        case GET_TUTORS_FAIL: {
            return {
                ...state,
            }
        }
        case GET_SUBJECT_PAGE: {
            return {
                ...state,
                getSubjectPage_loading: true,
                subject_page: null,
                getPage_success: false
            }
        }
        case GET_SUBJECT_PAGE_SUCCESS: {
            return {
                ...state,
                getSubjectPage_loading: false,
                subject_page: action.data,
                getPage_success: true,
            }
        }
        case GET_SUBJECT_PAGE_ERROR: {
            return {
                ...state,
                getSubjectPage_loading: false,
                subject_page: null,
                getPage_success: false
            }
        }
        case GET_PAGES: {
            return {
                ...state
            }
        }
        case GET_PAGES_SUCCESS:  {
            return {
                ...state,
                pages: action.data
            }
        }
        case GET_PAGES_ERROR: {
            return {
                ...state,
            }
        }
        default: 
            return state
    }
}
export const faqs = [
  {
    title: "1. How much does tutoring cost",
    content: [
      "Your first lesson is ONLY $1. This is because we want to ensure you’re 100% certified with your tutoring before setting up a tutoring plan. Our tutoring rate starts at $27.5/hr. We let you design a plan that allows you to pay weekly, biweekly, or monthly at a rate you can afford.",
      "At OnlineMathTutor, We let our customers choose what they can pay for online and one-on-one math tutoring. Paying a little more helps to offset the cost for families who might not be able to afford to tutor regularly. With this approach, we can make private tutoring more accessible and affordable for everyone."
    ],
  },
  {
    title: "2. Do you offer a trial lesson?",
    content: [
      "Yes, we do! We let you pay $1 for your first 30 lessons, a trial lesson, to ensure the tutor is a good fit."
    ],
  },
  {
    title: "3. Do I work with the same tutor each lesson?",
    content: [
      "Yes! You can work with the same tutor if you like the tutor you’re working with. We also have an option for on-demand tutoring, which lets you work with whichever tutor is available."
    ],
  },
  {
    title: "4. What are the qualifications of your tutor?",
    content: [
      "College students, PhD candidates, and teachers from top universities",
      "They have a minimum Math GPA of 3.5",
      "Have extensive experience in tutoring math online",
    ],
  },
  {
    title: "5. What if I need more than my online math tutor?",
    content: [
      "We refund you for the lesson and assign you a different tutor at no additional cost."
    ],
  },
  {
    title: "6. Do you provide homework help?",
    content: [
      "Yes! We help with homework for K -12 and College students"
    ],
  },
  {
    title: "7. What math do you tutor?",
    content: [
      "We tutor math for all levels, K -12, College, and Test Prep."
    ],
  },
  {
    title: "8. How do I become a math tutor?",
    content: [
      "Click on the ‘become a tutor” at the footer of this website to apply."
    ],
  },
  {
    title: "9. Where do you offer tutoring services?",
    content: [
      "We only offer online tutoring, but our sister company Frog Tutoring offers both in-person and online tutoring."
    ],
  },
  {
    title: "10. What if I miss a tutoring lesson?",
    content: [
      "If you miss a tutoring session, several things can happen. If the student is a no-show to the lesson without any prior communication, you will be charged 100% for the lesson. If you need to cancel the session with less than 24 hours' notice, you will be charged 50% of the lesson price. If you cancel with more than 24 hours' notice, your tutoring credit will remain the same, and you can reschedule with your tutor."
    ],
  },
  {
    title: "11. What if I don’t like the tutor I was assigned?",
    content: [
      "OnlineMathTutor has a 100% tutor satisfaction policy. If you don’t like the tutor you are assigned, we will assign you a new tutor at no additional cost."
    ],
  },
  {
    title: "12. How are OnlineMathTutor qualified?",
    content: [
      "Tutors are required to maintain a minimum 3.5 GPA in their areas of tutoring specifications.",
      "Prior tutoring experience",
      "All tutors must pass a background check",
      "All tutors must complete OnlineMathTutor training",
      "We look for tutors with empathy who care deeply about the success of their students.",
      "We look for mentors and not just tutors",
    ],
  },
  {
    title: "13. My child is not motivated. Can you help?",
    content: [
      "Yes! Our tutors are passionate about education and tutoring and are experts in getting their students excited about school."
    ],
  },
  {
    title: "14. How to become a math tutor online?",
    content: [
      "To become a math tutor online, you will need to have a strong understanding of mathematics and be able to explain complex concepts in a way that is easy for students to understand. Additionally, you will need to have a reliable internet connection and a computer or other device with a camera and microphone, so that you can communicate with your students online. Here are some steps you can follow to become a math tutor online:",
      "1. Start by gaining a thorough understanding of the mathematics you will be teaching. This may involve reviewing math concepts that you learned in school, taking additional classes or workshops, or studying on your own using online resources or books",
      "2. Consider getting certified as a math teacher or tutor. Many organizations offer certification programs that can help you demonstrate your knowledge and skills to potential students and clients",
      "3. Create a profile on a tutoring platform or website. Many online tutoring platforms allow you to create a profile where you can describe your education, experience, and tutoring style. You can also upload a photo and other information to help potential students learn more about you",
      "4. Set your rates and availability. Most online tutoring platforms allow you to set your own rates and schedule, so you can choose how much you want to charge and when you are available to tutor. Be sure to set competitive rates, but also reflect your experience and expertise",
      "5. Start advertising your services. Once your profile is set up, you can promote your services to potential students. You can do this by sharing your profile on social media, reaching out to friends and family members who may be interested in tutoring, or by participating in online tutoring forums or communities",
      "6. Provide high-quality tutoring services to your students. As a math tutor, it is important to provide your students with clear and concise explanations and plenty of opportunities to practice and apply what they are learning. Be patient, supportive, and always willing to answer questions and provide additional help as needed",
      "By following these steps, you can become a successful math tutor online. Good luck! Try again"
    ],
  },
  {
    title: "15. How to tutor math online?",
    content: [
      "a. Start by gaining a thorough understanding of the mathematics you will be teaching. This may involve reviewing math concepts that you learned in school, taking additional classes or workshops, or studying on your own using online resources or books",
      "b. Consider getting certified as a math teacher or tutor. Many organizations offer certification programs that can help you demonstrate your knowledge and skills to potential students and clients",
      "c. Create a profile on a tutoring platform or website. Many online tutoring platforms allow you to create a profile where you can describe your education, experience, and tutoring style. You can also upload a photo and other information to help potential students learn more about you",
      "d. Set your rates and availability. Most online tutoring platforms allow you to set your own rates and schedule, so you can choose how much you want to charge and when you are available to tutor. Be sure to set rates that are competitive, but also reflect your experience and expertise",
      "e. Start advertising your services. Once you have your profile set up, you can start promoting your services to potential students. You can do this by sharing your profile on social media, reaching out to friends and family members who may be interested in tutoring, or by participating in online tutoring forums or communities",
      "f. Provide high-quality tutoring services to your students. As a math tutor, it is important to provide your students with clear and concise explanations, as well as plenty of opportunities to practice and apply what they are learning. Be patient, supportive, and always willing to answer questions and provide additional help as needed"
    ],
  },
  {
    title: "16. How much do online math tutors make?",
    content: [
      "a. The amount of money that online math tutors make can vary depending on several factors, including their experience level, education, and the rates they charge. In general, online tutors can expect to earn between $15 and $120 per hour, with some tutors earning more or less depending on their qualifications and the services they offer",
      "b. Many online tutoring platforms allow tutors to set their own rates, so the amount you earn will depend on the rates you choose to charge. It may be helpful to research the rates of other tutors in your area to determine a competitive rate that reflects your experience and expertise",
      "c. In addition to the money you earn from tutoring, you may also be able to earn additional income by offering additional services, such as homework help or test preparation. You can maximize your earnings as an online math tutor by offering a range of services and setting competitive rates"
    ],
  },
  {
    title: "17. What is the best math tutor online?",
    content: [
      "It is difficult to determine the best math tutor online, as different tutors may be better suited to different students based on their teaching style, qualifications, and experience. To find the best math tutor for your needs, it is important to do some research and consider your individual learning style and needs",
      "Here are some steps you can follow to find the best math tutor online for you:",
      "1. Determine your goals and objectives. Before you start looking for a math tutor, it is important to have a clear idea of what you want to achieve. Are you looking for help with a specific math concept or topic, or do you need more general math support? Having clear goals can help you find a tutor who is well-suited to your needs.",
      "2. Research tutoring platforms and websites. There are many online tutoring platforms and websites that connect students with tutors. Take some time to research these platforms and compare their features and services to find one that is a good fit for you",
      "3. Read reviews and ask for recommendations. Many tutoring platforms and websites allow students to leave reviews of their tutors, which can be a helpful resource when choosing a tutor. You can also ask friends, family members, or teachers for recommendations on tutors who have helped them in the past.",
      "4. Consider a tutor's qualifications and experience. When choosing a math tutor, it is important to consider their qualifications and experience. Look for tutors who have a strong background in math and teaching, and who have experience working with students at your level.",
      "5. Try out a few tutors. Once you have a shortlist of potential tutors, consider trying out a few tutors to see which one is the best fit for you. Many tutoring platforms offer a trial period or a money-back guarantee, so you can try out a tutor without committing to a long-term relationship.",
      "6. By following these steps, you can find the best math tutor online for your needs. Good luck!"
    ],
  },
  {
    title: "18. How much is an online math tutor?",
    content: [
      "a. The cost of an online math tutor can vary depending on several factors, including the tutor's qualifications, experience, and the rates they charge. Online tutors can generally expect to charge between $15 and $120 per hour for their services",
      "b. Many online tutoring platforms allow tutors to set their rates, so the cost of a tutor will depend on the rates they choose to charge. It may be helpful to research the rates of other tutors in your area to determine a competitive rate that reflects your experience and expertise",
      "c. In addition to the cost of tutoring, you may also need to pay a fee to use the tutoring platform or website. These fees can vary, so be sure to check with the platform before you start working with a tutor. By considering these costs, you can determine how much you can expect to pay for an online math tutor"
    ],
  },
  {
    title: "19. Is math tutoring free online?",
    content: [
      "a. While there are some free online resources that can help you with math, it is generally not possible to get free one-on-one math tutoring online. Most online tutoring services charge a fee for their services, and many tutors also charge for their time",
      "b. There are, however, some free online math resources that you can use to help you with your math studies. These resources can include online tutorials, videos, and practice problems that can help you learn and practice math concepts on your own. Some websites also offer free online math games and activities that can make learning math more fun and engaging",
      "c. If you are looking for free math tutoring, you may be able to find free tutoring services through your school or local library. Some schools and libraries offer free tutoring programs for students who need extra help with their studies. You can also ask your math teacher or school counselor for recommendations on free tutoring services in your area"
    ],
  },
  {
    title: "20. Where can I get math help online?",
    content: [
      "There are many places where you can get math help online. One option is to use an online tutoring service like OnlineMathTutor.com, which can connect you with a tutor who can help you with your specific math questions. Another option is to use an online math forum to post your math questions and get answers from other users. Additionally, many schools and colleges offer online tutoring services for their students, so you may want to check with your school to see if this is an option for you. Finally, many websites offer a range of free math resources, such as tutorials, practice problems, and interactive tools, that can help you learn and practice math concepts on your own"
    ],
  },
];
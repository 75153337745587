import {
    GET_TV_DATA,
    GET_TV_DATA_FAIL,
    GET_TV_DATA_SUCCESS,
    GET_CHANNEL_DETAIL,
    GET_CHANNEL_DETAIL_FAIL,
    GET_CHANNEL_DETAIL_SUCCESS,
    GET_TUTORS,
    GET_TUTORS_FAIL,
    GET_TUTORS_SUCCESS,
    GET_PAGES_SUCCESS,
    GET_PAGES_ERROR,
    GET_PAGES,
    GET_SUBJECT_PAGE_SUCCESS,
    GET_SUBJECT_PAGE_ERROR,
    GET_SUBJECT_PAGE
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import { 
  getTvData as getTvDataApi,
  getChannelDetail as getChannelDetailApi,
  getTutors as getTutorsApi,
  getPages as getPagesApi,
  getSubjectPage as getSubjectPageApi
 } from 'src/Api/Core';

function* getTvData() {
    try {
        const result = yield getTvDataApi();
        if (result && result.channels) {
          yield put({ type: GET_TV_DATA_SUCCESS, data: result });
        } else {
          yield put({ type: GET_TV_DATA_FAIL, data: null });
        }
      } catch (err) {
        alert(err);
      }
}

export function* watchGetTvData() {
    yield(takeLatest(GET_TV_DATA, getTvData))
}

function* getChannelDetail(payload) {
  try {
    const result = yield getChannelDetailApi(payload.channel_id);
    if (result.data) {
      yield put({ type: GET_CHANNEL_DETAIL_SUCCESS, data: result.data });
    } else {
      yield put({ type: GET_CHANNEL_DETAIL_FAIL, data: null });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetChannelDetail() {
  yield(takeLatest(GET_CHANNEL_DETAIL, getChannelDetail))
}

function* getTutors() {
  try {
    const result = yield getTutorsApi();
    if (result.data) {
      yield put({ type: GET_TUTORS_SUCCESS, data: result.data });
    } else {
      yield put({ type: GET_TUTORS_FAIL, data: null });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetTutors() {
  yield(takeLatest(GET_TUTORS, getTutors))
}

function* getPages() {
  try {
    const result = yield getPagesApi();
    if (result.data) {
      sessionStorage.setItem('subject_pages', JSON.stringify(result.data))
      yield put({ type: GET_PAGES_SUCCESS, data: result.data });
    } else {
      yield put({ type: GET_PAGES_ERROR, data: null });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetPages() {
  yield(takeLatest(GET_PAGES, getPages))
}

function* getSubjectPage(payload) {
  try {
    const result = yield getSubjectPageApi(payload.page_id);
    if (result.data) {
      yield put({ type: GET_SUBJECT_PAGE_SUCCESS, data: result.data });
    } else {
      yield put({ type: GET_SUBJECT_PAGE_ERROR, data: null });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetSubjectPage() {
  yield(takeLatest(GET_SUBJECT_PAGE, getSubjectPage))
}
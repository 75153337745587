import React from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import homeStartImg from 'src/Assets/Images/Landing/landing-header-img.png';
import homeStartImg2 from 'src/Assets/Images/Landing/landing-img-2.svg';
import TutorsImg from 'src/Assets/Images/Landing/TutorsImg.png';
import AliceCarousel from 'react-alice-carousel';
import _ from 'lodash'

export default function HomeStart({subject}) {
  const navigate = useNavigate();

  const onTutor = () => navigate('/sign-up/step-1');

  const onStudent = () => navigate('/sign-up/step-1');

  return (
    <div className="home-start-component">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 h-c v-r order-lg-first order-second">
            <p>RATES STARTS AT $27/HR. TRY IT FOR $1</p>
            <h1>{`Book Top \nOnline ${_.isEmpty(subject) ? 'Math' : subject} Tutors`}</h1>
            <h4>{`1-on-1 Online ${_.isEmpty(subject) ? 'Math' : subject} Tutors \nThat Are 🇺🇸 U.S. Based`}</h4>
            <div className='btn-content'>
              <div className='btn-detail'>Tell us more about you: </div>
              <div className='btn-group'>
                <div className='btn-2 parent-btn' onClick={onTutor}>PARENT/GUADIAN</div>
                <div className='btn-2 student-btn' onClick={onStudent}>STUDENT</div>
              </div>
              <div className='tutors-count'>
                <img src={TutorsImg}/>
                <div><span className='bold'>17,000</span> K-12 and College students<br></br> tutored by our online math tutors</div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-lg-second order-first">
            <AliceCarousel
               autoPlay={true}
               infinite={true}
               autoPlayInterval={3000}
               disableDotsControls={true}
               disableButtonsControls={true}
            >
               <img src={homeStartImg} alt="home-img" className="home-img" />
               <img src={homeStartImg2} alt="home-img" className="home-img" />
            </AliceCarousel>
          </div>
        </div>
      </div>
    </div>
  );
}
import React from "react";
import './style.scss';
import ContentTable from "../ContentTable";
import { ReactSVG } from "react-svg";
import checkIcon from 'src/Assets/Images/Landing/check.svg';
import _ from 'lodash';

export default function ServiceDetailInfo({page}) {

    // const Qualifications = [
    //     'College students, PhD candidates, and teachers from top US universities',
    //     'Undergo a vigorous vetting, testing and training and pass a background check to become a tutor.',
    //     'All tutors a required to give a trial demo lesson at a cost of $1 to student to ensure tutor is the perfect fit.',
    //     'Adhere to our core values of excellence, faith, empathy and family.',
    //     'Tutors are required to submit session outline progress, learning goals and more'
    // ]

    const Qualifications = [
        "Affiliated with premier US universities: undergraduates, Bachelor's, Master's, and PhD degree holders, as well as teachers and professors.",
        "Subjected to a stringent vetting, testing, and training regimen.",
        "Compulsory background verification for every tutor.",
        "Provision of a $1 trial lesson to validate tutor-student fit.",
        "Upholding core principles: excellence, faith, empathy, and family.",
        "Tutors consistently provide: session blueprints, educational objectives, and progress reports."
    ]

    const HowItWorks = [
        'Submit the tutor request form',
        'Get connected with a tutor',
        'Complete your trial lesson for $1',
        'If the tutor is a good fit, pick your plan starting at $27/hr',
        'If tutor is not a good fit, we assign you a new tutor at no additional cost',
        'You only start paying for if your 100% happy with your tutor.'
    ]

    const getColor = (index) => {
        if (index % 3 === 0) return 'green'
        return index % 3 === 1 ? 'yellow' : 'blue'
    }
    
    return (
        <div className="service-detail-info landing-part">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <ContentTable page={page}/>
                    </div>
                    <div className="col-lg-8 right-content">
                        <div className="subject-info" id="sub-page-1">
                            <div className="sub-title">{page.subject.name} Tutoring Online</div>
                            <h2>{page.subject_title}</h2>
                            <p dangerouslySetInnerHTML={{ __html: page.description }}></p>
                        </div>
                        <div className="tutor-qualifications" id="sub-page-2">
                            <h3>Tutor Qualifications</h3>
                            <div className="list-content">
                                {
                                    Qualifications.map((item, index) => (
                                        <div className="item-row" key={index}>
                                            <ReactSVG src={checkIcon} className="check-icon" />
                                            <div className="text">{item}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="steps-involved" id="sub-page-3">
                            <h3>How It Works</h3>
                            <div className="list-content">
                               {
                                HowItWorks.map((item, index) => (
                                    <div className="item-row">
                                        <div className={`num-wrapper ${getColor(index)}`} key={index}>{`0${index + 1}`}</div>
                                        <div className="text">{item}</div>
                                    </div>
                                ))
                               }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
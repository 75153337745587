import {
  SET_STUDENT_INFO,
} from '../Type';

const INITIAL = {
  studentInfo: {
    level_id: "",
    subject_id: "",
    tutoring_need: "",
    start_date: "",
    start_time: "",    
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * Sidebar of the Main Dashboard
     */
    case SET_STUDENT_INFO: {
      const { studentInfo } = action.data;
      return { ...state, studentInfo };
    }

    default:
      return state;
  }
};




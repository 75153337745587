import React, { useEffect } from "react";
import './style.scss';
import { Blog, FAQs, HomeStart, KeySubjectConcepts, PricingSection, ServiceTimes, SubjectTutorServiceSection, TutorsAndRemarks, Universities } from "src/Components";
import ServiceDetailInfo from "src/Components/Landing/ServiceDetailInfo";
import ExampleTutorSession from "src/Components/Landing/ExampleTutorSession";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPagesAction, getSubjectBlogsAction, getSubjectPageAction } from "src/Redux/Actions";
import { hideSpinner, showSpinner } from "src/Utils/Helper";
import _ from 'lodash';
import { GET_PAGES_SUCCESS } from "src/Redux/Type";
import { Helmet } from "react-helmet";

export default function CourseLandingPage() {
    const dispatch = useDispatch()
    const params = useParams();
    const subject = params.subject.replaceAll("-", " ")

    const {
        pages,
        subject_page,
        getSubjectPage_loading,
        getPage_success
    } = useSelector(state => state.Core)

    const {
        subject_blogs
    }  = useSelector(state => state.Blogs)

    useEffect(() => {
        showSpinner()
        const cached_pages = JSON.parse(sessionStorage.getItem('subject_pages'))
        if(_.isEmpty(cached_pages)) {
            dispatch(getPagesAction())
        } else {
            dispatch({type: GET_PAGES_SUCCESS, data: cached_pages})
        }
        dispatch(getSubjectPageAction(params.id))
        dispatch(getSubjectBlogsAction(params.id))
    }, [params.id])

    useEffect(() => {
        if(!getSubjectPage_loading && getPage_success) {
            hideSpinner()
            console.log("=== page data ===", subject_page)
        }
    }, [getSubjectPage_loading, getPage_success, dispatch])

    const getStructuralData = (faqs) => {
        const structuralData = {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": faqs.map(item => {
            return {
            "@type": "Question",
            "name": item.question,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "<p>"+ item.answer +"</p>"
              }
            }
          })
        }
        return structuralData;
    }

    function SubjectMeta() {
        const meta_title = _.isEmpty(subject_page.meta_title) ? `24/7 Online ${subject_page.subject.name} Tutoring For Homework, Quiz, Tests & Exams` : subject_page.meta_title
        const meta_description = _.isEmpty(subject_page.meta_description) ? subject_page.description : subject_page.meta_description
        // const image_alt = subject_page.image_alt ?? ''
        const meta_image = _.isEmpty(subject_page.meta_image) ? 'https://i.ibb.co/jykShNN/Online-Math-Tutor-1.jpg' : subject_page.meta_image

        return (
            <Helmet>
                <title>{meta_title}</title>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href}/>
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title}/>
                <meta name="twitter:description" content={meta_description}/>
                <meta name="twitter:image" content={meta_image} />
                
                <script type="application/ld+json">{ JSON.stringify(getStructuralData(subject_page.faqs))}</script>
            </Helmet>
        );
    }

    return (
       <>
        { subject_page && <SubjectMeta /> }
        <div className="course-landing-page">
            <HomeStart subject={subject}/>
            <Universities subject={subject}/>
            <ServiceTimes />
            {
                subject_page && (
                    <>
                        <ServiceDetailInfo page={subject_page}/>
                        <TutorsAndRemarks page={subject_page}/>
                        <ExampleTutorSession page={subject_page}/>
                        <KeySubjectConcepts page={subject_page}/>
                        <FAQs 
                            title={`FAQ'S On ${subject_page.subject.name} and Online ${subject_page.subject.name} Tutoring`} 
                            pageFaqs={subject_page.faqs} 
                            showGrid={true}/>
                    </>
                )
            }
            <PricingSection showGrid={true}/>
            { (pages && subject_page) && <SubjectTutorServiceSection pages={pages} page={subject_page}/>}
            { subject_blogs && <Blog blogs={subject_blogs}/> }
        </div>
       </>
    )
}
import React from "react";
import './style.scss';
import TutorCard from "./TutorCard";
import Media from "react-media";
import TutorImg1 from 'src/Assets/Images/Landing/TutorImage1.png';
import { TutorsRightButton, TutorsLeftButton } from "src/Constant";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import _ from 'lodash';

export default function TopRatedTutors({itemCount, className, tutors, subject}) {

    return (
        <div className={`top-rated-tutors ${className}`}>
            <div className="container tutors-container">
                <div className="title">Top Rated Online {_.isEmpty(subject) ? 'Math' : subject} Tutors <br></br><span className="green">98%</span> of students give their tutor a <span className="yellow">5 star</span></div>
                <div className="col-lg-12 main-row">
                    <Media
                        queries={{
                            lg: "(min-width: 1200px)",
                            md: "(min-width: 992px) and (max-width: 1199px)",
                            sm: "(min-width: 768px) and (max-width: 991px)",
                            normal: "(max-width: 767px)",
                        }}
                    >
                    {(matches) => (
                        <OwlCarousel
                            className="owl-theme"
                            nav
                            stagePadding={
                                matches.lg ? 0 : matches.md ? 0 : matches.sm ? 0 : 15
                            }
                            margin={
                                matches.lg ? 28 : matches.md ? 33 : matches.sm ? 45 : 20
                            }
                            items={matches.lg ? itemCount : matches.md ? 3 : matches.sm ? 2 : 1}
                            navText={[TutorsLeftButton, TutorsRightButton]}
                        >
                        {tutors.map((item, index) => {
                            return <TutorCard key={index} tutor={item} team={false}/>;
                        })}
                        </OwlCarousel>
                    )}
                    </Media>
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormInput, SignupContainer } from 'src/Components';
import {
  CardNumberValidation,
  CardExpDateValidation,
  CvcValidation,
  ZipCodeValidation
} from 'src/Utils/Validation';
import {
  expDateFormat,
} from 'src/Utils/Format';
import { showSpinner, hideSpinner, showToast } from 'src/Utils/Helper';
import { getBraintreeKey, nonceUpdate, firstCharge } from 'src/Redux/Actions';
import { braintainService } from 'src/Utils/PaymentServices';

export default function PaymentStep() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getLoadingRef = useRef(false);
  const NonceLoadingRef = useRef(false);
  const FirstChargeLoadingRef = useRef(false);

  const {
    braintreeKey, get_braintreeKey_success, get_braintreeKey_loading,
    nonceUpdateStatus, nonceUpdateStatus_success, nonceUpdateStatus_loading,
    firstChargeRes, firstCharge_success, firstCharge_loading,
  } = useSelector(
    state => state.Payment,
  );

  const [cardInfo, setCardInfo] = useState({
    cardNumber: '',
    expDate: '',
    cvc: '',
    zipCode: '',
  });

  const [cardInfoValidation, setCardInfoValidation] = useState({
    cardNumberValid: null,
    expDateValid: null,
    cvcValid: null,
    zipCodeValid: null,
  });

  //Get BraintreeKey Res
  useEffect(() => {
    if (getLoadingRef.current && !get_braintreeKey_loading) {
      if (get_braintreeKey_success) {
        braintainService(braintreeKey, cardInfo).then((res) => {
          sendNonce(res);
        })
          .catch(err => {
            hideSpinner();
            showToast("error", 3000, "Invalid Card Info")
          });
      } else {
        hideSpinner();
      };
    }
    getLoadingRef.current = get_braintreeKey_loading;
  }, [braintreeKey, get_braintreeKey_loading, get_braintreeKey_success]);

  //Update Nonce Res
  useEffect(() => {
    if (NonceLoadingRef.current && !nonceUpdateStatus_loading) {
      if (nonceUpdateStatus_success) {
        showToast("success", 3000, nonceUpdateStatus)
        dispatch(firstCharge());
      } else {
        hideSpinner();
        showToast("error", 3000, nonceUpdateStatus)
      }
    }
    NonceLoadingRef.current = nonceUpdateStatus_loading;
  }, [nonceUpdateStatus_loading, nonceUpdateStatus_success, nonceUpdateStatus]);

  //First Charge Res
  useEffect(() => {
    if (FirstChargeLoadingRef.current && !firstCharge_loading) {
      hideSpinner();
      console.log("===firstChargeRes===", firstChargeRes)
      if (firstCharge_success) {
        showToast("success", 3000, firstChargeRes.message)
        navigate('/sign-up/step-7');
      } else {
        showToast("error", 3000, firstChargeRes.message)
      }
    }
    FirstChargeLoadingRef.current = firstCharge_loading;
  }, [firstCharge_loading, firstCharge_success, firstChargeRes, navigate]);

  const sendNonce = (nonce) => {
    const formData = new FormData()
    formData.append('todo', 'new');
    formData.append('nonce', nonce);
    dispatch(nonceUpdate(formData));
  };

  const onChangeCardInfo = (field, validationField, value) => {
    if (field === 'expDate') {
      setCardInfo({
        ...cardInfo,
        [field]: expDateFormat(value)
      });
    } else {
      setCardInfo({
        ...cardInfo,
        [field]: value,
      });
    }

    setCardInfoValidation({
      ...cardInfoValidation,
      [validationField]: getValidation(field, value)
    });
  };

  const getValidation = (field, value) => {
    if (field === 'cardNumber')
      return CardNumberValidation(value)
    if (field === 'expDate')
      return CardExpDateValidation(value)
    if (field === 'cvc')
      return CvcValidation(value)
    return ZipCodeValidation(value)
  };

  const checkDisableBtn = () => {
    if (
      CardNumberValidation(cardInfo.cardNumber) &&
      CardExpDateValidation(cardInfo.expDate) &&
      CvcValidation(cardInfo.cvc) &&
      ZipCodeValidation(cardInfo.zipCode)
    )
      return false;
    return true;
  }

  const onSignup = () => {
    if (!checkDisableBtn()) {
      // navigate('/sign-up/step-7');
      showSpinner();
      dispatch(getBraintreeKey());
    }
  };

  return (
    <SignupContainer
      step={6}
      title="Payment"
      className='signup-step6'
    >
      <div className='sub-title text-center'>
        {`We only charge your $1 for your first 30 min \nsession to make sure tutor is a good fit.`}
      </div>
      <div className='col-lg-12 pv-3' >
        <FormInput
          placeholder={"Card number"}
          type={'number'}
          validation={cardInfoValidation.cardNumberValid}
          value={cardInfo.cardNumber}
          onChange={e => onChangeCardInfo('cardNumber', 'cardNumberValid', e)}
        />
      </div>
      <div className='row pv-3'>
        <div className='col-lg-6 col-md-6 col-sm-12 col-12' >
          <FormInput
            label={"Expire date"}
            placeholder={"Expire date(MM/YY)"}
            className="exp-date"
            validation={cardInfoValidation.expDateValid}
            value={cardInfo.expDate}
            onChange={e => onChangeCardInfo('expDate', 'expDateValid', e)}
          />
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 col-12' >
          <FormInput
            placeholder={"CVS"}
            type={'number'}
            validation={cardInfoValidation.cvcValid}
            value={cardInfo.cvc}
            className="last-name"
            onChange={e => onChangeCardInfo('cvc', 'cvcValid', e)}
          />
        </div>
      </div>
      <div className='col-lg-12 pv-3' >
        <FormInput
          placeholder={"Zip code"}
          type={'number'}
          validation={cardInfoValidation.zipCodeValid}
          value={cardInfo.zipCode}
          onChange={e => onChangeCardInfo('zipCode', 'zipCodeValid', e)}
        />
      </div>
      <div className={`done-btn btn-2 ${checkDisableBtn() ? 'disable' : ''}`} onClick={onSignup}>SIGN UP</div>
    </SignupContainer>
  );
}
/* @flow */

import { combineReducers } from 'redux';

import Modal from './Modal';
import Sidebar from './Sidebar';
import Student from './Student';
import MailChimp from './MailChimp';
import Blogs from './Blogs';
import Review from './Review';
import Plan from './Plan';
import Level from './Level';
import Subject from './Subject';
import Frequency from './Frequency';
import StartDate from './StartDate';
import Auth from './Auth';
import Payment from './Payment';
import Core from './Core';

export default combineReducers ({
    Modal,
    Sidebar,
    Student,
    MailChimp,
    Blogs,
    Review,
    Plan,
    Level,
    Subject,
    Frequency,
    StartDate,
    Auth,
    Payment,
    Core
})
import {
  SIGN_IN,
  FORGOT_PASSWORD,
  STUDENT_SIGN_UP,
  TUTOR_SIGN_UP,
  PROFILE_UPDATE,
} from '../Type';

export const signIn = (data) => {
  return {
    type: SIGN_IN,
    data
  }
}

export const forgotPassword = (data) => {
  return {
    type: FORGOT_PASSWORD,
    data
  }
}

export const studentSignUP = (data) => {
  return {
    type: STUDENT_SIGN_UP,
    data
  }
}

export const tutorSignUP = (data) => {
  return {
    type: TUTOR_SIGN_UP,
    data
  }
}

export const profileUpdate = (data) => {
  return {
    type: PROFILE_UPDATE,
    data
  }
}
import {
  GET_ALL_REVIEWS, GET_ALL_REVIEWS_SUCCESS, GET_ALL_REVIEWS_FAIL
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
  getAllReviews as getAllReviewsApi
} from 'src/Api';

function* getAllReviews() {
  try {
    const result = yield getAllReviewsApi()
    if (result.data && result.data.length > 0) {
      yield put({ type: GET_ALL_REVIEWS_SUCCESS, data: result.data })
    } else {
      yield put({ type: GET_ALL_REVIEWS_FAIL, data: [] })
    }
  } catch (err) {
    yield put({ type: GET_ALL_REVIEWS_FAIL, data: [] })
  }
}

export function* watchGetAllReviews() {
  yield takeLatest(GET_ALL_REVIEWS, getAllReviews)
}
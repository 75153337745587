import React from "react";
import './style.scss';
import ContentTable from "../ContentTable";

export default function KeySubjectConcepts({page}) {

    return (
        <div className="key-subject-concepts landing-part" id="sub-page-4">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-8">
                        <h3>{page.key_concepts_title ?? `What To Know About ${page.subject.name}`}</h3>
                        <p dangerouslySetInnerHTML={{ __html: page.key_concepts }}></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useEffect } from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';

export default function DemoPage() {
  const navigate = useNavigate();

  useEffect(() => {
    var stored = localStorage.getItem('demoRoom')

    if (!stored || stored.indexOf('room.sh') === -1) {
      stored = null
    }

    fetch("https://api.thelessonspace.com/v2/demo/", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ desired_room: stored })
    }).then(function (response) {
      response.json().then(function (j) {
        localStorage.setItem('demoRoom', j.url)
        document.getElementById("lessonspaceDemoIframe").src = j.url
      })
    });

  }, []);

  const onSignUp = () => navigate('/sign-up/step-1');

  return (
    <div className="demo-page">
      <div className="container demo-container">
        <div className="title">The Best Online Tutoring Platform. Try It Today!</div>
        <div className="send-box">
          <input className="email-input" placeholder="Enter your Email" />
          <button className="send-btn" onClick={onSignUp}>FREE TRIAL LESSON</button>
        </div>
        <div className="demo-list shadow-object">
          <iframe
            id="lessonspaceDemoIframe"
            title='demo'
            frameborder="0"
            allow="camera; microphone; display-capture"
            src="about:blank" style={{ width: '100%', height: '100%' }}>
          </iframe>
        </div>
        <div className='btn-2 start-btn' onClick={onSignUp}>FREE TRIAL LESSON</div>
      </div>
    </div>
  );
}
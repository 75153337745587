import React from "react";
import './style.scss';
import ContentTable from "../ContentTable";
import TopRatedTutors from "../TopRatedTutors";
import Reviews from "../Reviews";

export default function TutorsAndRemarks({page}) {

    return (
        <div className="tutors-and-remarks landing-part">
           <div className="tutors-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 d-flex align-items-center"></div>
                        <div className="col-lg-8" id="sub-page-6">
                            <TopRatedTutors 
                                itemCount={2} 
                                className={'pt-0'}
                                tutors={page.tutors}
                                subject={page.subject.name} />
                        </div>
                    </div>
                </div>
           </div>
           <div className="container">
                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-8">
                        <Reviews title={'Reviews from parents and students'} itemCount={2}/>
                    </div>
                </div>
           </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import './style.scss';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SelectBtn, SignupContainer } from 'src/Components';
import { setStudentInfo } from 'src/Redux/Actions';

export default function StateDateStep() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    startDates,
  } = useSelector((state) => state.StartDate);

  const {
    studentInfo,
  } = useSelector((state) => state.Student);

  const [selectIndex, setSelectIndex] = useState('');

  useEffect(() => {
    if (studentInfo.start_date) {
      const _selectIndex =
        startDates.findIndex(temp => temp === studentInfo.start_date);
      setSelectIndex(_selectIndex);
    }
  }, [startDates, studentInfo.start_date]);

  const onSelectLevel = (index) => {
    const start_date = startDates[index];
    dispatch(setStudentInfo({ ...studentInfo, start_date }));
    setSelectIndex(index);
    setTimeout(() => {
      navigate('/sign-up/step-4');
    }, 500);
  };

  return (
    <SignupContainer
      step={3}
      title="When do you want to get started?"
      className='signup-step3'
    >
      <div className='row'>
        {!_.isEmpty(startDates) && startDates.map((item, index) => (
          <div className='col-lg-6 col-md-6 col-sm-6 col-6' key={index}>
            <SelectBtn
              title={item}
              index={index}
              selectIndex={selectIndex}
              onClick={onSelectLevel}
            />
          </div>
        ))}
      </div>
    </SignupContainer>
  );
}
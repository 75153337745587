import React, { useEffect, useState } from 'react';
import './style.scss';
import { Privacy, Refund, Term } from './infos';

function innerFunc(item) {
  return { __html: item.content }
}

export default function SiteInfoPage() {

  const [title, setTitle] = useState('');
  const [infos, setInfos] = useState([]);

  useEffect(() => {
    let title = '';
    let infos = [];

    if (window.location.pathname === '/privacy') {
      title = 'Privacy';
      infos = Privacy;
    } else if (window.location.pathname === '/refund') {
      title = 'Refund';
      infos = Refund;
    } else if (window.location.pathname === '/terms') {
      title = 'Terms And Conditions';
      infos = Term;
    }
    setTitle(title);
    setInfos(infos);

  }, []);

  return (
    <div className="siteinfo-page">
      <div className="container siteinfo-container">
        <div className="title">{title}</div>
        <div className="siteinfo-list shadow-object">
          {
            infos.map((item, index) => {
              return (
                <div className="siteinfo" key={index}>
                  <div className="siteinfo-title">{item.title}</div>
                  <div className="siteinfo-content" dangerouslySetInnerHTML={innerFunc(item)}></div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}
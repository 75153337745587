import React from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import freeBg from 'src/Assets/Images/Landing/free-bg.png';
import {
  Pricing,
} from 'src/Components';

export default function PricingSection({showGrid = false}) {
  const navigate = useNavigate();

  const onTrial = () => navigate('/sign-up/step-1');

  return (
    <div className="pricing-section-component landing-part" id="section-pricing">
      <div className='container'>
        <div className="row">
          {  showGrid && <div className='col-lg-4'></div> }
          <div className={`price-wrapper ${showGrid ? 'col-lg-8': ''}`}>
            <div className="container pricing-container">
                <h2>Pricing</h2>
                <div className="show-web-flex">
                  <div className="description mx-auto ">
                    <p>
                      Choose how much to pay and your first lesson is
                      <span>
                        <img src={freeBg} alt="right-bg" className="ml-2" />
                        <span className='free-txt'>$1</span>
                      </span>
                    </p>
                  </div>
                </div>

                <div className="show-mobile mx-auto">
                  <div className="description text-center">
                    Choose how much to pay and your first lesson is
                    <span>
                      <img src={freeBg} alt="right-bg" className="ml-2" />
                      <span className='free-txt'>$1</span>
                    </span>
                  </div>
                </div>

                <Pricing 
                  isNextBtn = {false}
                />
                <div className='btn-2 trial-btn' onClick={onTrial}>START FREE TRIAL</div>
              </div>
          </div>                        
        </div>
      </div>
    </div>
  );
}
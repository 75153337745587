import { GET_BLOGS, GET_BLOGS_FAIL, GET_BLOGS_SUCCESS, GET_SUBJECT_BLOGS, GET_SUBJECT_BLOGS_FAIL, GET_SUBJECT_BLOGS_SUCCESS } from '../Type';

const INITIAL = {
  blogs: [],
  subject_blogs: null,
  get_blogs_success: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    case GET_BLOGS: {
      return {
        ...state,
      }
    }
    case GET_BLOGS_FAIL: {
      return {
        ...state,
        blogs: [],
        get_blogs_success: false,
      }
    }
    case GET_BLOGS_SUCCESS: {
      let blogs = action.data || [];
      return {
        ...state,
        blogs: blogs,
        get_blogs_success: true,
      }
    }
    case GET_SUBJECT_BLOGS: {
      return {
        ...state,
      }
    }
    case GET_SUBJECT_BLOGS_FAIL: {
      return {
        ...state,
        subject_blogs: null,
      }
    }
    case GET_SUBJECT_BLOGS_SUCCESS: {
      return {
        ...state,
        subject_blogs: action.data,
      }
    }

    default:
      return state;
  }
}
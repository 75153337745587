import { Routes, Route } from 'react-router-dom';
import { AuthHeader, Universities } from 'src/Components';
import {
  LevelStep,
  FrequencyStep,
  StateDateStep,
  SubjectStep,
  AccountStep,
  PaymentStep,
  FinalVideoStep,
  PlanStep,
  PaymentEnrollStep,
} from 'src/Pages';

function SignUpRoutes() {
  return (
    <div>
      <AuthHeader />
      <div className='main-content'>
        <Routes>
          <Route exact path="/step-1" element={<LevelStep />} />
          <Route exact path="/step-2" element={<FrequencyStep />} />
          <Route exact path="/step-3" element={<StateDateStep />} />
          <Route exact path="/step-4" element={<SubjectStep />} />
          <Route exact path="/step-5" element={<AccountStep />} />
          <Route exact path="/step-6" element={<PaymentStep />} />
          <Route exact path="/step-7" element={<FinalVideoStep />} />
          <Route exact path="/plan" element={<PlanStep />} />
          <Route exact path="/payment-enroll" element={<PaymentEnrollStep />} />
        </Routes>
      </div>
      <Universities className='signup-university' />
    </div>
  );
}

export default SignUpRoutes;

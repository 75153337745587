import { Routes, Route } from 'react-router-dom';
import { AuthHeader } from 'src/Components';
import { TutorInfoStep, AvailabilityStep, TrainingVideoStep } from 'src/Pages';

function TutorSignUpRoutes() {
  return (
    <div>
      <AuthHeader />
      <div className='main-content'>
        <Routes>
          <Route exact path="/tutor-info" element={<TutorInfoStep />} />
          <Route exact path="/availability" element={<AvailabilityStep />} />
          <Route exact path="/train-videos" element={<TrainingVideoStep />} />
        </Routes>
      </div>
    </div>
  );
}

export default TutorSignUpRoutes;

import React, { useEffect, useState } from 'react';
import './style.scss';
import _ from 'lodash'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ArticlePreview, BlogStartArea, BlogFooter } from 'src/Components';

export default function BlogListPage() {
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState('All');
  const [categoryToShow, setCategoryToShow] = useState(6);
  const [Articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [articleToShow, setArticleToShow] = useState(3);
  const [categories, setCategories] = useState(3);
  const [remainingCategory, setRemainingCategory] = useState(0);
  const [remainingArticle, setRemainingArticle] = useState(0);

  const {
    blogs,
  } = useSelector((state) => state.Blogs);

  useEffect(() => {
    if (!_.isEmpty(blogs)) {
      const category = ["All"];
      blogs.forEach((element) => {
        if (!category.includes(element.topic.name)) {
          category.push(element.topic.name);
        }
      });
      setFilteredArticles(blogs);
      setCategories(category);
      setArticles(blogs);
    }
  }, [blogs]);

  useEffect(() => {
    setRemainingCategory(categories.length - categoryToShow);
    setRemainingArticle(filteredArticles.length - articleToShow);
  }, [blogs, articleToShow, categories.length, categoryToShow, filteredArticles.length]);


  const changeCategory = (category) => {
    if (selectedCategory !== category) {
      const listToShow =
        category === "All"
          ? Articles
          : Articles.filter((item) => item.topic.name === category);
      setSelectedCategory(category);
      setFilteredArticles(listToShow);
      setArticleToShow(3);
    }
  };

  const onMore = () => setArticleToShow(articleToShow + 2);

  const navigateToArticle = (item) => {
    navigate(`/blog/${item.url}`);
  };

  return (
    <div className="bloglist-page">
      <BlogStartArea />
      <div className="container bloglist-container">
        <div className="categories-area">
          <h1>Categories</h1>
          <div className="category-list">
            {!_.isEmpty(categories) &&
              categories.map((item, index) => {
                return index < categoryToShow ? (
                  <div
                    className={`category ${selectedCategory === item ? "selected" : ""
                      }`}
                    key={index}
                    onClick={() => changeCategory(item)}
                  >
                    {" "}
                    {item}
                  </div>
                ) : (
                  <div style={{ display: "none" }} key={index} />
                );
              })}
            {remainingCategory > 0 && (
              <div
                className="category category_plus"
                onClick={() =>
                  setCategoryToShow(categoryToShow + 6)
                }
              >
                +{remainingCategory} categories
              </div>
            )}
          </div>
        </div>
        <div className="article-area">
          {filteredArticles.map((item, index) => {
            return index >= articleToShow ? (
              <div style={{ display: "none" }} key={index} />
            ) : index === 0 ? (
              <div className="row article-row first-row" key={index}>
                <div className="col-lg-12">
                  <ArticlePreview
                    data={item}
                    first
                    onClick={() => navigateToArticle(item)}
                  />
                </div>
              </div>
            ) : index % 2 === 1 ? (
              <div className="row article-row" key={index}>
                <div className="col-lg-6">
                  <ArticlePreview
                    data={filteredArticles[index]}
                    onClick={() => navigateToArticle(item)}
                  />
                </div>
                <div className="col-lg-6 second-col">
                  {filteredArticles[index + 1] && (
                    <ArticlePreview
                      data={filteredArticles[index + 1]}
                      onClick={() => navigateToArticle(item)}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div style={{ display: "none" }} key={index} />
            );
          })}
        </div>

        {remainingArticle > 0 && (
          <div className="btn-area">
            <button
              className="load-more"
              onClick={onMore}
            >
              Load More
            </button>
          </div>
        )}
      </div>
      <BlogFooter />
    </div>
  );
}
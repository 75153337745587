import React from 'react';
import './style.scss';
// import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function ContactUsPage() {

  const defaultProps = {
    center: {
      lat: 32.726789,
      lng: -97.359885
    },
    zoom: 11
  };

  return (
    <div className="contactus-page">
      <div className="container contactus-container">
        <div className="title">Contact Us</div>
        <div className="row shadow-object main-row">
          <div className="col-lg-6 left-area">
            Online Math Tutor Corporate Office <br />
            1751 River Run Suite 200 <br />
            Fort Worth, TX 76107 <br /> <br />
            {/* <b>Phone: (877) 904 0134</b><br /> */}
            <b>Email: info@onlinemathtutor.com </b>
          </div>
          <div className="col-lg-6 right-area">
            <div className="map-wrapper">
              <div className="map-container">
                {/* <GoogleMapReact
                  bootstrapURLKeys={{ key: "" }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                >
                  <AnyReactComponent
                    lat={32.726789}
                    lng={-97.359885}
                    text="My Marker"
                  />
                </GoogleMapReact> */}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
import React from 'react';
import './style.scss';
import checkIcon from 'src/Assets/Images/Landing/check.svg';

export default function CheckDescription({
  className = '', description = ''
}) {

  return (
    <div className={`check-description-component ${className}`}>
      <img src={checkIcon} className="check-icon" alt='check-icon' />
      <div className='description ml-2'>{description}</div>
    </div>
  );
}
import React, { useEffect, useState } from 'react';
import './style.scss';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SelectBtn, SignupContainer } from 'src/Components';
import { setStudentInfo } from 'src/Redux/Actions';

export default function FrequencyStep() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    frequency,
  } = useSelector((state) => state.Frequency);

  const {
    studentInfo,
  } = useSelector((state) => state.Student);

  const [selectIndex, setSelectIndex] = useState('');

  useEffect(() => {
    if (studentInfo.start_time) {
      const _selectIndex =
        frequency.findIndex(temp => temp === studentInfo.start_time);
      setSelectIndex(_selectIndex);
    }
  }, [frequency, studentInfo.start_time]);

  const onSelectLevel = (index) => {
    const start_time = frequency[index];
    dispatch(setStudentInfo({ ...studentInfo, start_time }));
    setSelectIndex(index);
    setTimeout(() => {
      navigate('/sign-up/step-3');
    }, 500);
  };

  return (
    <SignupContainer
      step={2}
      title="How often will you need tutoring?"
      className='signup-step2'
    >
      <div className='row'>
        {!_.isEmpty(frequency) && frequency.map((item, index) => (
          <div className='col-lg-6 col-md-6 col-sm-6 col-6' key={index}>
            <SelectBtn
              title={item}
              index={index}
              selectIndex={selectIndex}
              onClick={onSelectLevel}
            />
          </div>
        ))}
      </div>
    </SignupContainer>
  );
}
import React from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg'
import commaLeft from 'src/Assets/Images/Landing/comma-left.svg';
import commaRight from 'src/Assets/Images/Landing/comma-right.svg';

export default function LeadMathTutor() {

  // const videoLink = "https://muse.ai/vc/9PViFjS-Math-Techniques-and-Strategies"

  return (
    <div className="lead-math-tutor landing-part">
      <div className="container">
        <h2>Lead Online Math Tutor</h2>
        <div className="row">
          <div className="col-lg-6 left-content">
          <iframe 
              width="100%" 
              height="370" 
              src="https://www.youtube.com/embed/pAsBP3mQawc" 
              title="3 Tips to Get the MOST out of your tutor!" 
              frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowfullscreen></iframe>
          </div>
          <div className="col-lg-6 h-c v-r">
            <ReactSVG src={commaLeft} className="comma-left" />
            <p>
              Taylor H is an Engineer and husband.
              He has thousands of hours tutoring math.
              Learn a few tips from from him on how to effectively get tutor in math online.
            </p>
            <ReactSVG src={commaRight} className="comma-right" />
          </div>
        </div>
      </div>
    </div>
  );
}
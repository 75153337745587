import React from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import totorGroup from 'src/Assets/Images/Signup/tutor-group.png'
import star from 'src/Assets/Images/Signup/star.svg'
import { Progress } from 'src/Components';
import leftArrow from 'src/Assets/Images/Common/arrow-left-2.svg'

export default function SignupContainer({
  className = '', step, title = '', children
}) {
  const navigate = useNavigate();

  const onBack = () => navigate(-1);

  return (
    <div className={`signup-container ${className}`}>
      <div className='container'>
        <div className='back-content'>
          <div className='v-c back-btn' onClick={onBack}>
            <img src={leftArrow} alt='arrow' className='arrow-btn' />
            <div className='back-txt'>Back</div>
          </div>
        </div>
        <div className='signup-content shadow-object'>
          <div className='header'>
            {step < 6 && <Progress step={step} />}
            <h2>{title}</h2>
          </div>

          <div className='body'>
            {children}
          </div>

          <div className='footer'>
            <div className='v-c'>
              <img src={totorGroup} alt='tutor-group' />
              <div className='ml-2'>
                <div className='mark-content v-c'>
                  <img src={star} alt='star' />
                  <div className='mark'>4.8</div>
                </div>
                <span>Average rating from Students</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
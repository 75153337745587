import {
  GET_BRAINTREE_KEY,
  GET_BRAINTREE_KEY_SUCCESS,
  GET_BRAINTREE_KEY_FAIL,
  NONCE_UPDATE,
  NONCE_UPDATE_SUCCESS,
  NONCE_UPDATE_FAIL,
  FIRST_CHARGE,
  FIRST_CHARGE_SUCCESS,
  FIRST_CHARGE_FAIL,
} from '../Type';

const INITIAL = {
  braintreeKey: '',
  get_braintreeKey_loading: false,
  get_braintreeKey_success: false,

  nonceUpdateStatus: '',
  nonceUpdateStatus_loading: false,
  nonceUpdateStatus_success: false,

  firstChargeRes: '',
  firstCharge_loading: false,
  firstCharge_success: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * Get BrainTree Key
    */
    case GET_BRAINTREE_KEY: {
      return {
        ...state,
        get_braintreeKey_loading: true,
        get_braintreeKey_success: false,
      }
    }
    case GET_BRAINTREE_KEY_FAIL: {
      return {
        ...state,
        braintreeKey: '',
        get_braintreeKey_loading: false,
        get_braintreeKey_success: false,
      }
    }
    case GET_BRAINTREE_KEY_SUCCESS: {
      return {
        ...state,
        braintreeKey: action.data.token,
        get_braintreeKey_loading: false,
        get_braintreeKey_success: true,
      }
    }

    /**
     * Nonce Update
    */
    case NONCE_UPDATE: {
      return {
        ...state,
        nonceUpdateStatus_loading: true,
        nonceUpdateStatus_success: false,
      }
    }
    case NONCE_UPDATE_FAIL: {
      return {
        ...state,
        nonceUpdateStatus: '',
        nonceUpdateStatus_loading: false,
        nonceUpdateStatus_success: false,
      }
    }
    case NONCE_UPDATE_SUCCESS: {
      return {
        ...state,
        nonceUpdateStatus: action.data.message,
        nonceUpdateStatus_loading: false,
        nonceUpdateStatus_success: true,
      }
    }

    /**
     * first Charge
    */
    case FIRST_CHARGE: {
      return {
        ...state,
        firstCharge_loading: true,
        firstCharge_success: false,
      }
    }
    case FIRST_CHARGE_FAIL: {
      return {
        ...state,
        firstChargeRes: action.data,
        firstCharge_loading: false,
        firstCharge_success: false,
      }
    }
    case FIRST_CHARGE_SUCCESS: {
      return {
        ...state,
        firstChargeRes: action.data,
        firstCharge_loading: false,
        firstCharge_success: true,
      }
    }

    default:
      return state;
  }
}
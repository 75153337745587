import { GET_ALL_REVIEWS, GET_ALL_REVIEWS_FAIL, GET_ALL_REVIEWS_SUCCESS } from '../Type';

const INITIAL = {
  reviews: [],
  get_review_success: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    case GET_ALL_REVIEWS: {
      return {
        ...state,
      }
    }
    case GET_ALL_REVIEWS_FAIL: {
      return {
        ...state,
        reviews: [],
        get_review_success: false,
      }
    }
    case GET_ALL_REVIEWS_SUCCESS: {
      let reviews = action.data || [];
      return {
        ...state,
        reviews: reviews,
        get_review_success: true,
      }
    }

    default:
      return state;
  }
}
import trustpilot from "src/Assets/Images/Landing/SeeOn/trustpilot.svg"
import bbc from "src/Assets/Images/Landing/SeeOn/bbc.svg"
import google from "src/Assets/Images/Landing/SeeOn/google.svg"
import yelp from "src/Assets/Images/Landing/SeeOn/yelp.svg"
import consumer from "src/Assets/Images/Landing/SeeOn/consumer.svg"
import fox from "src/Assets/Images/Landing/SeeOn/fox.svg"
import nbc from "src/Assets/Images/Landing/SeeOn/nbc.svg"
import glassdoor from "src/Assets/Images/Landing/SeeOn/glassdoor.svg"
import abc from "src/Assets/Images/Landing/SeeOn/abc.svg"
import cbs from "src/Assets/Images/Landing/SeeOn/cbs.svg"

export const SeeOnInfo = [
  { id: 1, logo: trustpilot },
  { id: 2, logo: bbc },
  { id: 3, logo: google },
  { id: 4, logo: yelp },
  { id: 5, logo: consumer },
  { id: 6, logo: fox },
  { id: 7, logo: nbc },
  { id: 8, logo: glassdoor },
  { id: 9, logo: abc },
  { id: 10, logo: cbs },
];
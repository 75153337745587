import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CheckBox, CheckDescription } from 'src/Components';
import { profileUpdate } from 'src/Redux/Actions';
import { showSpinner, hideSpinner, showToast } from 'src/Utils/Helper';
import { GetDashboardUrl } from 'src/Constant';

export default function PaymentEnrollStep() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getLoadingRef = useRef(false);

  const {
    profile_update_res, profile_update_success, profile_update_loading, user, token
  } = useSelector(
    state => state.Auth,
  );

  const {
    currentPlan,
  } = useSelector((state) => state.Plan);

  const [isPolicyCheck, setIsPolicyCheck] = useState(false);

  const toggleCheckBox = () => setIsPolicyCheck(!isPolicyCheck);

  useEffect(() => {
    if (getLoadingRef.current && !profile_update_loading) {
      hideSpinner();
      if (profile_update_success) {
        window.location.href = GetDashboardUrl(user, token);
      } else {
        showToast('error', 3000, profile_update_res);
      };
    }
    getLoadingRef.current = profile_update_loading;
  }, [dispatch, profile_update_loading, profile_update_success, navigate, profile_update_res, user, token]);

  const getUnit = () => {
    let unit =
      currentPlan.name === 'Weekly' ? 'Week' :
        currentPlan.name === 'Biweekly' ? 'Biweek' :
          currentPlan.name === 'Monthly' ? 'Month' : 'One Time'
    return unit
  }

  const getBalance = () => {
    let price = Math.abs(currentPlan.hour * currentPlan.rate).toFixed(2);
    return `$${price}`
  }

  const getHourUnit = () => {
    let unit = currentPlan.hour === 1 ? 'hour' : 'hours'
    return unit
  }

  const onEnroll = () => {
    if (!isPolicyCheck) return

    const payload = {
      plan_frequency: currentPlan.name,
      plan_tutoring_hour: currentPlan.hour,
      plan_rate_per_hour: currentPlan.rate,
    };
    console.log("payload", payload);
    showSpinner()
    dispatch(profileUpdate(payload));
  };

  if (_.isEmpty(currentPlan))
    return null

  return (
    <div className='payment-enroll-step-component'>
      <div className="container">
        <div className="payment-enroll-step-container shadow-object mx-auto">
          <div className='enroll-header'>
            <div className='plan-name' >{`${currentPlan.name} Payment Plan`}</div>
            <h2>{`${getBalance()}/${getUnit()}`}</h2>
          </div>
          <div className='enroll-body'>
            <CheckDescription
              description={`$${currentPlan.rate}/hour`}
            />
            <CheckDescription
              description={`${currentPlan.hour} tutoring ${getHourUnit()} ${currentPlan.name}`}
            />
            <CheckDescription
              description='Suspend and cancel your account at any time - no contracts'
            />
            <CheckDescription
              description={`Recurring ${currentPlan.name} payment`}
            />
            <div className='policy-content'>
              <CheckBox
                isCheck={isPolicyCheck}
                onClick={toggleCheckBox}
              />
              <div className='policy-text'>
                On the GradeGetter platform, your subscription includes a set number of tutoring minutes for a specified period of time. The tutoring minutes do not roll over from one period to another. Cancel at any time. You are billed either weekly, biweekly, monthly, or one time depending on your plan. Additional tutoring sessions not covered by your plan will be billed individually.
                Your membership will be renewed automatically at the beginning of each cycle until you cancel. Tutor satisfaction guaranteed:
                If you do not like your tutor that you are assigned, we assign you a new tutor at no additional cost
              </div>
            </div>
          </div>
          <div className='enroll-footer center'>
            <div className={`btn-2 next-btn ${!isPolicyCheck ? 'disable' : ''}`} onClick={onEnroll}>ENROLL</div>
          </div>
        </div>
      </div>
    </div>
  );
}
import { Api } from "./Api";

export function* getTvData() {
    const jsonData = yield Api.get("/gradetv")
    return jsonData
}

export function* getChannelDetail(channel_id) {
    const jsonData = yield Api.get(`/channels/${channel_id}`)
    return jsonData
}

export function* getTutors() {
    const jsonData = yield Api.get("/users/tutors")
    return jsonData
}

export function* getPages() {
    const jsonData = yield Api.get("/subjectpages")
    return jsonData
}

export function* getSubjectPage(page_id) {
    const jsonData = yield Api.get(`/subjectpages/${page_id}`)
    return jsonData
}
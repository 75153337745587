import React, { useEffect, useState } from 'react';
import './style.scss';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg'
import { PriceSlider } from 'src/Components';
import { setCurrentPlan } from 'src/Redux/Actions';

import arrowDown from "src/Assets/Images/Common/arrow-down.svg";
import arrowUp from "src/Assets/Images/Common/arrow-up.svg";

export default function Pricing({
  nextText = 'OK',
  isNextBtn = true,
  onNext,
}) {
  const dispatch = useDispatch();

  const {
    plans,
  } = useSelector((state) => state.Plan);

  useEffect(() => {
    if (!_.isEmpty(plans)) {
      setSelectedPlan({
        ...selectedPlan,
        ...plans[0],
        hour: plans[0].min_hour,
        rate: plans[0].min_rate
      });
    };
  }, [plans]);

  const [isSelectMenu, setIsSelectMenu] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    min_hour: 1,
    max_hour: 25,
    min_rate: 27.5,
    max_rate: 75,
    hour: 1,
    rate: 25,
  });

  const onChangePlan = (field, value) => {
    let newPlan = {
      ...selectedPlan,
      [field]: value,
    }
    setSelectedPlan(newPlan);
    dispatch(setCurrentPlan(newPlan));
  }

  const selectPlan = (item) => {
    let newPlan = {
      ...selectedPlan,
      ...item,
      hour: item.min_hour,
      rate: item.min_rate
    }
    setSelectedPlan(newPlan);
    dispatch(setCurrentPlan(newPlan));
    setIsSelectMenu(false);
  };

  const onMenu = () => setIsSelectMenu(!isSelectMenu);

  return (
    <>
      {!_.isEmpty(plans) && (
        <div className="pricing-component">
          <div className="pricing-header show-web-flex">
            {plans.map((item, index) => (
              <div
                className={`center  ${selectedPlan.name === item.name
                  ? "category-active"
                  : "category-unActive"
                  }`}
                key={index}
                onClick={() => selectPlan(item)}
              >
                {item.name}
              </div>
            ))}
          </div>

          <div
            className="show-mobile"
            style={{ width: "100%", paddingRight: 30 }}
          >
            <div className="pricing-mobile-header">
              <div className='v-c'>
                <div className="col-lg-10 col-md-10 col-sm-10 col-10 menu-left-position">
                  <div
                    className="center pricing-menu"
                    onClick={onMenu}
                  >
                    {selectedPlan.name}
                  </div>
                </div>
                <div
                  className="col-lg-2 col-md-2 col-sm-2 col-2 menu-right-position"
                  onClick={onMenu}
                >
                  <div className="drop-icon text-center">
                    <ReactSVG src={isSelectMenu ? arrowUp : arrowDown} />
                  </div>
                </div>
              </div>

              {isSelectMenu && (
                <div className="pricing-mobile-header-list">
                  <div className="col-lg-10 col-md-10 col-sm-10 col-10 menu-left-position">
                    {plans.map((item, index) => (
                      <div
                        className='pricing-menu'
                        key={index}
                        onClick={() => selectPlan(item)}
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="pricing-body">
            <div className="slider-block">
              <div className="slider-item">
                <div className="slider-des-header text-center">
                  {selectedPlan.hour}{selectedPlan.hour === 1 ? " hour" : " hours"}
                </div>
                <div className="slider-des-body text-center">
                  Hours needed per week?
                </div>
                <PriceSlider
                  min={selectedPlan.min_hour}
                  max={selectedPlan.max_hour}
                  value={selectedPlan.hour}
                  onChange={(v) => onChangePlan('hour', v)}
                />
              </div>
              <div className="slider-item">
                <div className="slider-des-header text-center">
                  ${selectedPlan.rate}/hour
                </div>
                <div className="slider-des-body text-center">
                  Choose your price
                </div>
                <PriceSlider
                  min={selectedPlan.min_rate}
                  max={selectedPlan.max_rate}
                  value={selectedPlan.rate}
                  onChange={(v) => onChangePlan('rate', v)}
                />
              </div>
            </div>
            <div className="total-price">
              <div className="text-center mx-auto">
                <span>
                  {`${selectedPlan.name} cost for ${selectedPlan.hour}${selectedPlan.hour === 1 ? " hour" : " hours"}: `}
                </span>
                <span className="price">
                  {`$${selectedPlan.hour * selectedPlan.rate}`}
                </span>
              </div>
            </div>
            <div className="note">
              <p>
                At OnlineMathTutor, We let our customers choose what they can pay for online and one-on-one math tutoring.
                Paying a little more helps to offset the cost for families who might not be able to afford tutoring regularly.
                With this approach, we are able to make private tutoring more accessible and affordable for everyone.
              </p>
            </div>
            {isNextBtn && <div className='next-btn-content center'>
              <div className='btn-2 next-btn' onClick={onNext}>{nextText}</div>
            </div>}
          </div>
        </div>
      )}
    </>
  );
}
import React, { useState } from 'react';
import './style.scss';
import { ReactSVG } from "react-svg";
import { NavLink, useNavigate } from 'react-router-dom';
import logo from 'src/Assets/Images/Logo/logo.png'
import googlePlay from 'src/Assets/Images/Landing/google-play-icon.svg';
import appleStore from 'src/Assets/Images/Landing/app-store-icon.svg';

import facebook from 'src/Assets/Images/Landing/Social/facebook.png';
import twitter from 'src/Assets/Images/Landing/Social/twitter.png';
import instagram from 'src/Assets/Images/Landing/Social/instagram.png';
import yt from 'src/Assets/Images/Landing/Social/yt.png';
import tiktok from 'src/Assets/Images/Landing/Social/tiktok.png';
import linkedin from 'src/Assets/Images/Landing/Social/linkedin.png';
import google from 'src/Assets/Images/Landing/Social/google.png';
import { useSelector } from 'react-redux';

export default function Footer() {
  const navigate = useNavigate()
  const [subjectCount, setSubjectCount] =  useState(2);
  const onBecomeTutor = () => {
    window.location.href = "https://gradegetter.com/tutor-home"
  }

  const {
    pages
  } = useSelector(state => state.Core)

  return (
    <div className="footer-component">
      <div className="container">
        <div className=' col-lg-12'>
          <div className='header'>
            <NavLink className="menu-item" exact to="/"><img src={logo} className="logo" alt="logo-active" /></NavLink>
            <div className='btn-2' onClick={onBecomeTutor}>BECOME A TUTOR</div>
          </div>
        </div>
        <div className='body'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='tag-title'>Download the app</div>
              <div className='stores'>
                <a href="https://play.google.com/store/apps/details?id=com.gradegetter&pli=1" target="_blank" rel="noopener noreferrer"><ReactSVG src={googlePlay} /></a>
                <a href="https://play.google.com/store/apps/details?id=com.gradegetter&pli=1" target="_blank" rel="noopener noreferrer"><ReactSVG src={appleStore} className='app-store' /></a>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='tag-title'>Company</div>
                  <div className='tag-body'>
                    <NavLink className="tag" exact to="/contact-us">Contact Us</NavLink>
                    <NavLink className="tag" exact to="/faqs">FAQ</NavLink>
                    <NavLink className="tag" exact to="/terms">Terms</NavLink>
                    <NavLink className="tag" exact to="/blog">Blog</NavLink>
                    <NavLink className="tag" exact to="/demo">Demo</NavLink>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='tag-title'>Our Brands</div>
                  <div className='tag-body'>
                    <a href="https://icodeedu.com/" className="tag" target="_blank" rel="noopener noreferrer">iCode Edu</a>
                    {/* <a href="https://gradegetter.com/" target="_blank" rel="noopener noreferrer">Grade TV</a> */}
                    {/* <a href="https://gradegetter.com/" target="_blank" rel="noopener noreferrer">Ask Franky</a> */}
                    {/* <a href="https://gradegetter.com/" target="_blank" rel="noopener noreferrer">Make it Make Sense</a> */}
                    <a href="https://gradegetter.com/" className="tag" target="_blank" rel="noopener noreferrer">Grade Getter</a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='tag-title'>Popular Math</div>
              <div className='tag-body'>
                {
                  pages && pages.slice(0, subjectCount).map((item, index) => (
                    <NavLink className="tag" exact to={`/subject/${item.subject.name.replace(/\s+/g, '-')}/${item.id}/#`} key={index}>Online {item.level.name} {item.subject.name} Tutoring</NavLink>
                  ))
                }
                { pages && pages.length > subjectCount && <div className="tag more" onClick={() => setSubjectCount(pages.length)}>+{pages.length - subjectCount}</div>}
              </div>
            </div>
            {/* <div className='col-lg-3'>
              <div className='tag-title'>Make it Make Sense</div>
              <div className='tag-body'>
                <NavLink className="tag" exact to="/tutor-sign-up">Make SAT Math Make Sense</NavLink>
                <NavLink className="tag" exact to="/tutor-sign-up">Make ACT Math Make Sense</NavLink>
                <NavLink className="tag" exact to="/tutor-sign-up">Make ASVAB Math Make Sense</NavLink>
                <NavLink className="tag" exact to="/tutor-sign-up">Make GRE Math Make Sense</NavLink>
                <div className="tag more">+3</div>
              </div>
            </div> */}
          </div>
        </div>
        <div className='footer'>
          <div className='footer-left'>
            <NavLink className="footer__link" exact to="/" >© 2022 OnlineMathTutor</NavLink>
            <NavLink className="footer__link" exact to="/terms" >Terms of Service</NavLink>
            <NavLink className="footer__link" exact to="/privacy" >Privacy Policy</NavLink>
          </div>
          <div className='footer-right v-c'>
            <a href="https://www.facebook.com/profile.php?id=100087964037161" className="social" target="_blank" rel="noopener noreferrer"><img src={facebook} alt='facebook' /></a>
            <a href="https://twitter.com/onlinemath01" className="social" target="_blank" rel="noopener noreferrer"><img src={twitter} alt='twitter' /></a>
            <a href="https://www.instagram.com/onlinemathtutor1/" className="social" target="_blank" rel="noopener noreferrer"><img src={instagram} alt='instagram' /></a>
            <a href="https://www.youtube.com/channel/UC6howHvJniKu7YypUvFps9Q/?guided_help_flow=5" className="social" target="_blank" rel="noopener noreferrer"><img src={yt} alt='yt' /></a>
            <a href="https://www.tiktok.com/@onlinemathtutor?lang=en" className="social" target="_blank" rel="noopener noreferrer"><img src={tiktok} alt='tiktok' /></a>
            <a href="https://www.linkedin.com/in/online-math-tutor-484ba0258/" className="social" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt='linkedin' /></a>
            <a href="https://goo.gl/maps/w5zMiqcKQpzQqGgA7" className="social" target="_blank" rel="noopener noreferrer"><img src={google} alt='google' /></a>
          </div>
        </div>
      </div>
    </div>
  );
}
import { GET_START_DATE, GET_START_DATE_FAIL, GET_START_DATE_SUCCESS } from '../Type';

const INITIAL = {
  startDates: [],
  get_startDates_success: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    case GET_START_DATE: {
      return {
        ...state,
      }
    }
    case GET_START_DATE_FAIL: {
      return {
        ...state,
        startDates: [],
        get_startDates_success: false,
      }
    }
    case GET_START_DATE_SUCCESS: {
      let startDates = action.data || [];
      return {
        ...state,
        startDates,
        get_startDates_success: true,
      }
    }

    default:
      return state;
  }
}
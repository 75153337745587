import React from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg'
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import tutors1 from 'src/Assets/Images/Landing/tutors1.png';
import tutors2 from 'src/Assets/Images/Landing/tutors2.png';
import checkIcon from 'src/Assets/Images/Landing/check.svg';
import { TutorInfo } from './tutorInfo';

export default function AboutOurTutors() {

  return (
    <div className="about-our-tutors landing-part">
      <div className="container">
        <h2>About Our Online Math Tutors</h2>
        <div className="row">
          <div className="col-lg-6">
            <AliceCarousel
              autoPlay={true}
              infinite={true}
              autoPlayInterval={7000}
              disableDotsControls={true}
              disableButtonsControls={true}
            >
              <img src={tutors1} alt='tutors1' />
              <img src={tutors2} alt='tutors2' />
            </AliceCarousel>
          </div>
          <div className="col-lg-6 h-c v-r">
            {TutorInfo.map((item, index) => (
              <div className='info-item h-r' key={index}>
                <ReactSVG src={checkIcon} className="check-icon" />
                <p>{item}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
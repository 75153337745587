import { GET_FREQUENCY, GET_FREQUENCY_FAIL, GET_FREQUENCY_SUCCESS } from '../Type';

const INITIAL = {
  frequency: [],
  get_frequency_success: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    case GET_FREQUENCY: {
      return {
        ...state,
      }
    }
    case GET_FREQUENCY_FAIL: {
      return {
        ...state,
        frequency: [],
        get_frequency_success: false,
      }
    }
    case GET_FREQUENCY_SUCCESS: {
      let frequency = action.data || [];
      return {
        ...state,
        frequency: frequency,
        get_frequency_success: true,
      }
    }

    default:
      return state;
  }
}
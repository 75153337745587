import { Routes, Route } from 'react-router-dom';
import { Header, Footer } from 'src/Components';
import { 
  LandingPage,
  SiteInfoPage,
  ContactUsPage,
  FAQsPage,
  DemoPage,
  BlogListPage,
  BlogArticlePage,
  CourseLandingPage,
 } from 'src/Pages';

function LandingRoutes() {
  return (
    <div>
      <Header />
      <div className='main-content'>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/terms" element={<SiteInfoPage />} />
          <Route exact path="/privacy" element={<SiteInfoPage />} />
          <Route exact path="/refund" element={<SiteInfoPage />} />
          <Route exact path="/contact-us" element={<ContactUsPage />} />
          <Route exact path="/faqs" element={<FAQsPage />} />
          <Route exact path="/demo" element={<DemoPage />} />
          <Route exact path="/blog" element={<BlogListPage />} />
          <Route exact path="/blog/:url" element={<BlogArticlePage />} />
          <Route exact path="/subject/:subject/:id" element={<CourseLandingPage />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default LandingRoutes;

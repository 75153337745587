import React, { useEffect, useState } from 'react';
import './style.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg'
import { useDispatch, useSelector } from 'react-redux';
import logo from 'src/Assets/Images/Logo/logo.png'
import phone from 'src/Assets/Images/Header/phone-call.png';
import phone_mobile from 'src/Assets/Images/Header/phone_mobile.png';
import angleDown from 'src/Assets/Images/Header/angle-down.png';
import hamburger from 'src/Assets/Images/Header/hamburger.svg';
import { getChannelDetailAction, toggleSideBar } from 'src/Redux/Actions';
import { Dropdown } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import { SET_CURRENT_CHANNEL } from 'src/Redux/Type';
import _ from 'lodash';
import { showSpinner } from 'src/Utils/Helper';

const phoneNumber = "817 631 9435";

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  // const history = useHistory

  const [transform, setTransform] = useState(false);

  const { tvData } = useSelector((state) => state.Core);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    setTransform(window.scrollY > 0 ? true : false)
  }

  const showInactiveHeader = () => {
    return !transform
  };

  const onOpenSideBar = () => dispatch(toggleSideBar(true));

  const getMenuTitle = (name) => {
    const text = name.replaceAll('Make', '').replaceAll('Sense', '').trim();
    let textArray = text.split(' ')
    return `<span>${textArray[0]}</span> ${textArray[1]}`
  }

  // const onCourseSelect = (item) => {
  //   var channel_name = item.name.replace(/\s+/g, '-');
  //   window.open(`https://gradegetter.com/channel/${channel_name}`, "_blank", "noreferrer");
  // }

  const getChannelData = (item) => {
    const currentChannel = JSON.parse(sessionStorage.getItem(`channel-${item.id}`))
    if (_.isEmpty(currentChannel)) {
        var channel_name = item.name.replace(/\s+/g, '-');
        showSpinner()
        dispatch(getChannelDetailAction(channel_name))
    } else {
        dispatch({type: SET_CURRENT_CHANNEL, data: { currentChannel } })
    }
    navigate('/#courses')
}

  return (
    <div className={`header-component ${showInactiveHeader() ? 'inactive' : ''}`}>
      <div className="container header-container v-c">
        <div className='v-c'>
          <NavLink className="menu-item show-web" exact to="/"><img src={logo} className="logo" alt="logo-active" /></NavLink>
          <a href={`tel:+1${phoneNumber}`}>
            <img src={phone_mobile} alt="phone_mobile" className="show-mobile p-3" />
          </a>
        </div>
        <div className="web-menu v-c show-web-flex">
          {
            tvData && (
              <Dropdown className='select-course'>
                <DropdownToggle>
                  <span>Free Courses</span>
                  <img src={angleDown}/>
                </DropdownToggle>
                <Dropdown.Menu>
                  {
                    tvData.channels.map((item, index) => (
                      <Dropdown.Item href="#/action-1" key={index} dangerouslySetInnerHTML={{ __html: getMenuTitle(item.name) }} onClick={() => getChannelData(item)}></Dropdown.Item>
                    ))
                  }
                </Dropdown.Menu>
              </Dropdown>
            )
          }
          <a href="https://gradegetter.com/tutor-home" className="menu-item text-menu" rel="noopener noreferrer">Become A Tutor</a>
          <NavLink className="menu-item text-menu" exact to="/blog">Blog</NavLink>
          <a className="menu-item text-menu" href="/#section-pricing" >Pricing</a>
          <NavLink className="menu-item text-menu" exact to="/demo">Demo</NavLink>
          <div className='phone-wrap show-web menu-item'>
            <img src={phone} alt="phone" className="p-3 show-web" />
            <div className="phone-txt">{phoneNumber}</div>
          </div>
          <NavLink className="box-menu sign-in" exact to="/auth/sign-in">SIGN IN</NavLink>
          <NavLink className="box-menu" exact to="/sign-up/step-1">GET STARTED</NavLink>
        </div>
        <ReactSVG src={hamburger} className="svg-icon show-mobile-flex" onClick={onOpenSideBar} />
      </div>
    </div>
  );
}
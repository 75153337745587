import React from "react";
import './style.scss';
import iconPlay from 'src/Assets/Images/Landing/icon-play.png';

export default function FreePrepCard({video, playVideo}) {

    return (
        <div className="prep-card">
            <div className="img-wrapper" onClick={playVideo}>
                <img src={video.meta_image}/>
                <div className="overlay"></div>
                <img src={iconPlay} className="icon-play"/>
            </div>
            <div className="content">
                <h4 dangerouslySetInnerHTML={{ __html: video.name }}></h4>
                <p dangerouslySetInnerHTML={{ __html: video.description }}></p>
            </div>
        </div>
    )
}
import React from 'react';
import './style.scss';
import _ from 'lodash'
import { useSelector } from 'react-redux';
import Media from "react-media";
import { CarouselLeftButton, CarouselRightButton } from "src/Constant";
import { Review } from 'src/Components';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function Reviews({title, itemCount = 3}) {

  const {
    reviews,
  } = useSelector((state) => state.Review);

  return (
    <div className="reviews landing-part">
      <div className="container reviews-container">
        { !_.isEmpty(title) ? <h2>{title}</h2> : <h2>Hear it from our parents and students</h2>}
        <div className="row">
          <div className="col-lg-12 main-row">
            {!_.isEmpty(reviews) &&
              <Media queries={{
                lg: "(min-width: 1200px)",
                md: "(min-width: 992px) and (max-width: 1199px)",
              }}>
                {matches => (
                  <OwlCarousel
                    className="owl-theme"
                    rewind
                    nav
                    stagePadding={matches.lg ? 20 : matches.md ? 20 : 15}
                    margin={matches.lg ? 28 : matches.md ? 33 : 20}
                    items={matches.lg ? itemCount : matches.md ? 2 : 1}
                    dots={false}
                    loop={false}
                    navText={[CarouselLeftButton, CarouselRightButton]}
                  >
                    {reviews.map((item, index) => <Review info={item} index={index} key={index} />)}
                  </OwlCarousel>
                )}
              </Media>}
          </div>
        </div>
      </div>
    </div>
  );
}
import {
    GET_TV_DATA,
    GET_CHANNEL_DETAIL,
    GET_TUTORS,
    GET_SUBJECT_PAGE,
    GET_PAGES
} from '../Type';

export const getTvDataAction = () => {
    return {
        type: GET_TV_DATA,
        data: {}
    }
}

export const getChannelDetailAction = (channel_id) => {
    return {
        type: GET_CHANNEL_DETAIL,
        channel_id
    }
}

export const getTutorsAction = () => {
    return  {
        type: GET_TUTORS,
        data: {}
    }
}

export const getPagesAction = () => {
    return {
        type: GET_PAGES,
        data: {}
    }
}

export const getSubjectPageAction = (page_id) => {
    return {
        type: GET_SUBJECT_PAGE,
        page_id
    }
}
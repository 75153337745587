import React, { useState } from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { OnlineInfo } from './onlineInfo';

export default function HowTutor() {
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(0);

  const onClickInfo = (index) => {
    setActiveIndex(index);
  };

  const onTryDemo = () => navigate('/demo')

  return (
    <div className="how-tutor landing-part">
      <div className="container">
        <h2>How we tutor math online (Tech)</h2>
        <div className="row">
          <div className="col-lg-6 left-content">
            <div className='row'>
              {OnlineInfo.map((item, index) => (
                <div className="col-lg-6" key={index}>
                  <div className={`how-info-item ${activeIndex === index && 'active'}`} onClick={() => onClickInfo(index)}>
                    {item.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6 right-content">
            <video width="100%" height="100%"
              key={activeIndex}
              controls>
              <source
                src={`${OnlineInfo[activeIndex].video}#t=0.01`}
                type="video/webm"
              />
            </video>
          </div>
        </div>
        <div className='btn-2 try-btn' onClick={onTryDemo}>
          TRY IT ON DEMO
        </div>
      </div>
    </div>
  );
}
import React, { useEffect, useState } from 'react';
import './style.scss';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ArticlePreview, ArticleHeader, BlogFooter } from 'src/Components';

function innerFunc(content) {
  return { __html: content }
}

export default function BlogArticlePage(props) {
  const navigate = useNavigate();

  const { url } = useParams();
  const {
    blogs,
  } = useSelector((state) => state.Blogs);

  const [article, setArticle] = useState('');

  useEffect(() => {
    const _article = blogs.filter(
      (item) => item.url === url
    );
    setArticle(_article && _article[0] ? _article[0] : null)
  }, [blogs, url]);

  const navigateToArticle = (url) => {
    navigate(`/blog/${url}`);
  };

  return (
    <div className="blogarticle-page">
      {article && (
        <>
          <ArticleHeader article={article} />
          <div className="container blogarticle-container">
            <div
              className="article-content"
              dangerouslySetInnerHTML={innerFunc(article.content)}
            />
            <div className="article-content">
              <div className="author-area">
                <img
                  src={process.env.REACT_APP_IMAGE_DOMAIN + article.poster.picture}
                  alt="author"
                  className="author-photo"
                />
                <div className="author-card shadow-object">
                  <div className="personal-info">
                    <div className="author-name">
                      About {article.poster.name}
                    </div>
                    <div className="author-role">
                      {article.poster.profile_title}
                    </div>
                  </div>
                  <div className="author-overview">
                    {article.poster.overview}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="otherarticle-content">
        <div
          className="container otherarticle-container"
        >
          {blogs && blogs.length > 2 && <h3>Read Other Articles</h3>}
          {blogs && blogs.length > 2 && (
            <div className="row article-row">
              <div className="col-lg-6">
                <ArticlePreview
                  data={blogs[1]}
                  onClick={() => navigateToArticle(blogs[1].url)}
                />
              </div>
              <div className="col-lg-6">
                <ArticlePreview
                  data={blogs[2]}
                  onClick={() => navigateToArticle(blogs[2].url)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <BlogFooter />
    </div>
  );
}
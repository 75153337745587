import {
  GET_PLAN,
  SET_CURRENT_PLAN,
} from '../Type';

export const getPlan = () => {
  return {
    type: GET_PLAN,
    data: {}
  }
}

export const setCurrentPlan = (currentPlan) => {
  return {
    type: SET_CURRENT_PLAN,
    data: { currentPlan }
  }
}
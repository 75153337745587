import React from "react";
import './style.scss';
import LevelAccordion from "../OnlineTutorServices/LevelAccordion";


export default function SubjectTutorServiceSection({pages, page}) {

    const groupedPages = pages.reduce((group, item) => {
        const { level } = item
        group[level.name] = group[level.name] ?? []
        group[level.name].push(item)
        return group
      }, {})
    
    return (
        <div className="tutor-service-section landing-part" id="sub-page-0">
            <div className="container">
                <h3 className="">Online Math Tutoring Services</h3>
                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-8 ps-0">
                       <div className="row w-100">
                            {
                                Object.keys(groupedPages).map((item, index) => (
                                    <div className="col-lg-6 tutor-sec mb-3 ps-0" key={index}>
                                        <LevelAccordion subPages={groupedPages[item]} level={item} className={'c-white'}/>
                                    </div>
                                ))
                            }
                       </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useMemo, useRef, useState } from "react";
import './style.scss';
import Media from "react-media";
import { FreePrepLeftButton, FreePrepRightButton } from "src/Constant";
import FreeActPrep from 'src/Assets/Images/Landing/FreeActPrep.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import FreePrepCard from "./FreePrepCard";
import { useDispatch, useSelector } from "react-redux";
import { getChannelDetailAction } from "src/Redux/Actions";
import { hideSpinner, showSpinner } from "src/Utils/Helper";
import { SET_CURRENT_CHANNEL } from "src/Redux/Type";
import _ from 'lodash';
import { useLocation } from "react-router-dom";

export default function FreeTestPreps({tvData}) {
    const dispatch = useDispatch()
    const { hash } = useLocation()
    const courseRef = useRef(null)
    const [prepIndex, setPrepIndex] = useState(tvData.channels[0].id)

    useEffect(() => {
        if (hash === '#courses') {
            courseRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    }, [hash])

    const {
        channel,
        loadingChannel
    } = useSelector(state => state.Core)

    useEffect(() => {
        getChannelData(tvData.channels[0])
    }, [])

    useEffect(() => {
        if(!loadingChannel) {
            hideSpinner()
            console.log(`=== channel data ===`, channel)
        }
    }, [loadingChannel])

    const channelVideos = useMemo(() => {
        let videoList = []
        if (channel && channel.id && channel.folders.length > 0) {
            channel.folders.forEach(element => {
                videoList.push(...element.videos)
            });
            setPrepIndex(channel.id)
        }
        return videoList
    }, [channel, dispatch])

    const getMenuTitle = (name) => {
        const text = name.replaceAll('Make', '').replaceAll('Sense', '').trim();
        let textArray = text.split(' ')
        return `<span>${textArray[0]}</span> ${textArray[1]}`
    }

    const getChannelData = (item) => {
        const currentChannel = JSON.parse(sessionStorage.getItem(`channel-${item.id}`))
        if (_.isEmpty(currentChannel)) {
            var channel_name = item.name.replace(/\s+/g, '-');
            showSpinner()
            dispatch(getChannelDetailAction(channel_name))
        } else {
            dispatch({type: SET_CURRENT_CHANNEL, data: { currentChannel } })
        }
    }

    const playVideo = (item) => {
        window.open(`https://gradegetter.com/gradeVideo/${item.id}`, "_blank", "noreferrer");
    }

    return (
        <div className="free-test-preps" ref={courseRef}>
            <div className="container prep-container">
                <div className="title"><span style={{ color: '#1BD0A5'}}>Free</span> Math Test Prep Courses</div>
                <div className="topic-header-wrapper">
                    <div className="topic-header">
                        {
                            tvData.channels.map((item, index) => (
                                <div className={`menu-item ${channel && channel.id === item.id ? 'active' : ''}`} onClick={() => getChannelData(item)} key={index} dangerouslySetInnerHTML={{ __html: getMenuTitle(item.name) }}></div>
                            ))
                        }
                    </div>
                </div>
                <div className="col-lg-12 main-row">
                    {
                        channel && (
                            <Media
                                queries={{
                                    lg: "(min-width: 1200px)",
                                    md: "(min-width: 992px) and (max-width: 1199px)",
                                    sm: "(min-width: 768px) and (max-width: 991px)",
                                    normal: "(max-width: 767px)",
                                }}
                            >
                            {(matches) => (
                                <OwlCarousel
                                    className="owl-theme"
                                    nav
                                    stagePadding={
                                        matches.lg ? 0 : matches.md ? 0 : matches.sm ? 0 : 15
                                    }
                                    margin={
                                        matches.lg ? 28 : matches.md ? 33 : matches.sm ? 45 : 20
                                    }
                                    key={channel.id}
                                    items={matches.lg ? 2 : matches.md ? 2 : matches.sm ? 1 : 1}
                                    navText={[FreePrepLeftButton, FreePrepRightButton]}
                                >
                                {channelVideos.map((item, index) => {
                                    return <FreePrepCard key={index} video={item} playVideo={() => playVideo(item)}/>;
                                })}
                                </OwlCarousel>
                            )}
                            </Media>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
import React from 'react';
import './style.scss';
import _ from 'lodash'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ArticlePreview } from 'src/Components';

export default function Blog({blogs}) {
  const navigate = useNavigate();

  const onMore = () => navigate('/blog');
  const onBlogDetails = (item) => navigate('/blog');

  return (
    <>
      {!_.isEmpty(blogs) && <div className="blog landing-part">
        <div className="container blog-container">
          <h2>Blog</h2>
          <div className="row main-row">
            {blogs.map((item, index) => (
              index < 2 && (<div className="col-lg-6" key={index}>
                <ArticlePreview
                  data={item}
                  onClick={() => onBlogDetails(item)}
                />
              </div>)
            ))}
          </div>
          <div className='btn-2 more-btn' onClick={onMore}>MORE</div>
        </div>
      </div>}
    </>
  );
}
import React from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { faqs } from 'src/Pages/Landing/FAQsPage/faqs';
import { Accordion } from 'src/Components';
import _ from 'lodash';

export default function FAQs({ title, pageFaqs, showGrid = false }) {
  const navigate = useNavigate();

  const onMore = () => navigate('/faqs')

  function HomeFaqs() {
    return (
      <div className="faqs landing-part">
        <div className="container faqs-container">
          <div className='row'>
            { showGrid && <div className='col-lg-4'></div> }
            <div className={showGrid ? 'col-lg-8': ''}>
              <h2>FAQs</h2>
              <div className="faqs-list shadow-object">
                {faqs.map((item, index) => (
                  index < 8 &&
                  <Accordion
                    className="accordion"
                    title={item.title}
                    content={item.content}
                    key={index}
                  />
                ))}
              </div>
              <div className='center'>
                <div className='btn-2 more-btn' onClick={onMore}>MORE</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function SubPageFaqs() {
    return (
      <div className="faqs landing-part">
        <div className="container faqs-container">
          <div className='row'>
            { showGrid && <div className='col-lg-4'></div> }
            <div className={showGrid ? 'col-lg-8': ''}>
              <h2>{title}</h2>
              <div className="faqs-list shadow-object">
                {pageFaqs.map((item, index) => (
                  <Accordion
                    className="accordion"
                    title={`${index + 1}. ${item.question}`}
                    answer={item.answer}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id='sub-page-7'>
      { _.isEmpty(title) ? <HomeFaqs /> : pageFaqs && pageFaqs.length > 0 ? <SubPageFaqs /> : <div></div>}
    </div>
  );
}
import { GET_LEVELS, GET_LEVELS_FAIL, GET_LEVELS_SUCCESS } from '../Type';

const INITIAL = {
  levels: [],
  get_levels_success: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    case GET_LEVELS: {
      return {
        ...state,
      }
    }
    case GET_LEVELS_FAIL: {
      return {
        ...state,
        levels: [],
        get_levels_success: false,
      }
    }
    case GET_LEVELS_SUCCESS: {
      let levels = action.data || [];
      return {
        ...state,
        levels: levels,
        get_levels_success: true,
      }
    }

    default:
      return state;
  }
}
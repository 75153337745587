/**
 * Post
 */
export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOGS_SUCCESS = 'GET_BLOGS_SUCCESS';
export const GET_BLOGS_FAIL = 'GET_BLOGS_FAIL';

export const GET_SUBJECT_BLOGS = 'GET_SUBJECT_BLOGS';
export const GET_SUBJECT_BLOGS_SUCCESS = 'GET_SUBJECT_BLOGS_SUCCESS';
export const GET_SUBJECT_BLOGS_FAIL = 'GET_SUBJECT_BLOGS_FAIL'

/**
 * Modal
 */
export const TOGGLE_REVIEW_MODAL = 'TOGGLE_REVIEW_MODAL';

/**
 * Sidebar
 */
export const TOGGLE_SIDE_BAR = 'TOGGLE_SIDE_BAR';

/**
 * HubSpot
 */
export const SIGN_UP_HUBSPOT = 'SIGN_UP_HUBSPOT';
export const SIGN_UP_HUBSPOT_SUCCESS = 'SIGN_UP_HUBSPOT_SUCCESS';
export const SIGN_UP_HUBSPOT_FAIL = 'SIGN_UP_HUBSPOT_FAIL';

/**
 * Student
 */
export const SET_STUDENT_INFO = 'SET_STUDENT_INFO';

/**
* MailChimp
*/
export const MAIL_CHIMP_ACTION = 'MAIL_CHIMP_ACTION';
export const MAIL_CHIMP_ACTION_SUCCESS = 'MAIL_CHIMP_ACTION_SUCCESS';
export const MAIL_CHIMP_ACTION_FAIL = 'MAIL_CHIMP_ACTION_FAIL';

/**
* Plan
*/
export const GET_PLAN = 'GET_PLAN';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export const GET_PLAN_FAIL = 'GET_PLAN_FAIL';

export const SET_CURRENT_PLAN = 'SET_CURRENT_PLAN';

/**
* Level
*/
export const GET_LEVELS = 'GET_LEVELS';
export const GET_LEVELS_SUCCESS = 'GET_LEVELS_SUCCESS';
export const GET_LEVELS_FAIL = 'GET_LEVELS_FAIL';

/**
* Subject
*/
export const GET_SUBJECTS = 'GET_SUBJECTS';
export const GET_SUBJECTS_SUCCESS = 'GET_SUBJECTS_SUCCESS';
export const GET_SUBJECTS_FAIL = 'GET_SUBJECTS_FAIL';

/**
* Frequency
*/
export const GET_FREQUENCY = 'GET_FREQUENCY';
export const GET_FREQUENCY_SUCCESS = 'GET_FREQUENCY_SUCCESS';
export const GET_FREQUENCY_FAIL = 'GET_FREQUENCY_FAIL';

/**
* Start Dates
*/
export const GET_START_DATE = 'GET_START_DATE';
export const GET_START_DATE_SUCCESS = 'GET_START_DATE_SUCCESS';
export const GET_START_DATE_FAIL = 'GET_START_DATE_FAIL';

/**
 * Review
 */
export const GET_ALL_REVIEWS = 'GET_ALL_REVIEWS';
export const GET_ALL_REVIEWS_SUCCESS = 'GET_ALL_REVIEWS_SUCCESS';
export const GET_ALL_REVIEWS_FAIL = 'GET_ALL_REVIEWS_FAIL';

/**
 * Sign In
 */
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';

/**
* Sign In
*/
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

/**
* Student Sign Up
*/
export const STUDENT_SIGN_UP = 'STUDENT_SIGN_UP';
export const STUDENT_SIGN_UP_SUCCESS = 'STUDENT_SIGN_UP_SUCCESS';
export const STUDENT_SIGN_UP_FAIL = 'STUDENT_SIGN_UP_FAIL';

/**
* Tutor Sign Up
*/
export const TUTOR_SIGN_UP = 'TUTOR_SIGN_UP';
export const TUTOR_SIGN_UP_SUCCESS = 'TUTOR_SIGN_UP_SUCCESS';
export const TUTOR_SIGN_UP_FAIL = 'TUTOR_SIGN_UP_FAIL';

/**
* Profile Updating
*/
export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAIL = 'PROFILE_UPDATE_FAIL';


/**
* Payment
*/
export const GET_BRAINTREE_KEY = 'GET_BRAINTREE_KEY';
export const GET_BRAINTREE_KEY_SUCCESS = 'GET_BRAINTREE_KEY_SUCCESS';
export const GET_BRAINTREE_KEY_FAIL = 'GET_BRAINTREE_KEY_FAIL';

export const NONCE_UPDATE = 'NONCE_UPDATE';
export const NONCE_UPDATE_SUCCESS = 'NONCE_UPDATE_SUCCESS';
export const NONCE_UPDATE_FAIL = 'NONCE_UPDATE_KEY_FAIL';

export const FIRST_CHARGE = 'FIRST_CHARGE';
export const FIRST_CHARGE_SUCCESS = 'FIRST_CHARGE_SUCCESS';
export const FIRST_CHARGE_FAIL = 'FIRST_CHARGE_KEY_FAIL'; 

export const GET_TV_DATA = 'GET_TV_DATA';
export const GET_TV_DATA_SUCCESS = 'GET_TV_DATA_SUCCESS';
export const GET_TV_DATA_FAIL = 'GET_TV_DATA_FAIL';

export const GET_CHANNEL_DETAIL =  'GET_CHANNEL_DETAIL';
export const GET_CHANNEL_DETAIL_SUCCESS = 'GET_CHANNEL_DETAIL_SUCCESS';
export const GET_CHANNEL_DETAIL_FAIL = 'GET_CHANNEL_DETAIL_FAIL';

export const GET_TUTORS = 'GET_TUTORS';
export const GET_TUTORS_SUCCESS = 'GET_TUTORS_SUCCESS';
export const GET_TUTORS_FAIL = 'GET_TUTORS_FAIL';

export const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL';

export const GET_SUBJECT_PAGE = 'GET_SUBJECT_PAGE';
export const GET_SUBJECT_PAGE_SUCCESS = 'GET_SUBJECT_PAGE_SUCCESS';
export const GET_SUBJECT_PAGE_ERROR = 'GET_SUBJECT_PAGE_ERROR';

export const GET_PAGES = 'GET_PAGES';
export const GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS';
export const GET_PAGES_ERROR = 'GET_PAGES_ERROR';
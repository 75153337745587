import {
  GET_PLAN, GET_PLAN_FAIL, GET_PLAN_SUCCESS,
  SET_CURRENT_PLAN,
} from '../Type';

const INITIAL = {
  plans: [],
  get_plan_success: false,
  currentPlan: {
    hour: 1,
    max_hour: 25,
    max_rate: 75,
    min_hour: 1,
    min_rate: 27.5,
    name: "Weekly",
    rate: 27.5,
  },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    case GET_PLAN: {
      return {
        ...state
      }
    }
    case GET_PLAN_FAIL: {
      return {
        ...state,
        plans: [],
        get_plan_success: false,
      }
    }
    case GET_PLAN_SUCCESS: {
      let plans = action.data || [];
      return {
        ...state,
        plans: plans,
        get_plan_success: true,
      }
    }

    case SET_CURRENT_PLAN: {
      const { currentPlan } = action.data;
      return {
        ...state,
        currentPlan
      }
    }

    default:
      return state;
  }
}
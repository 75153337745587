import React from 'react';
import './style.scss';
import AliceCarousel from "react-alice-carousel";
import mobileApp1 from 'src/Assets/Images/Landing/mobile-app1.svg';
import mobileApp2 from 'src/Assets/Images/Landing/mobile-app2.svg';
import { ReactSVG } from 'react-svg'

const Description = [
  { id: 1, number: '01', title: "Request a tutor" },
  { id: 2, number: '02', title: "Get connected with a tutor" },
  { id: 3, number: '03', title: "Get tutored and make better grades" },
]
export default function HowItWorks() {

  return (
    <div className="how-it-works landing-part">
      <div className="container main-content">
        <h2>How it Works</h2>
        <div className="row">
          <div className="col-lg-6 v-r h-c">
            {Description.map((item, index) => (
              <div className='v-c work-item' key={index}>
                <div className={`number-content center number-content-${index}`}>{item.number}</div>
                <div className='description'>{item.title}</div>
              </div>
            ))}
          </div>
          <div className="col-lg-6 right-area show-web">
            <AliceCarousel
              autoPlay={true}
              infinite={true}
              autoPlayInterval={7000}
              disableDotsControls={true}
              disableButtonsControls={true}
            >
              <img src={mobileApp1} alt='mobile-app'  />
              <img src={mobileApp2} alt='mobile-app'  />
            </AliceCarousel>
          </div>
        </div>
      </div>
    </div>
  );
}
import { GET_FREQUENCY, GET_FREQUENCY_SUCCESS, GET_FREQUENCY_FAIL } from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import { getFrequency as getFrequencyApi } from 'src/Api';

function* getFrequency() {
  try {
    const result = yield getFrequencyApi();
    if (result) {
      yield put({ type: GET_FREQUENCY_SUCCESS, data: result });
    } else {
      yield put({ type: GET_FREQUENCY_FAIL, data: [] });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetFrequency() {
  yield takeLatest(GET_FREQUENCY, getFrequency);
}

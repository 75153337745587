import React, { useEffect } from 'react';
import './style.scss';
import { ReactSVG } from "react-svg";
import arrow from "src/Assets/Images/Common/arrow-right.svg";

function innerFunc(item) {
  return { __html: item.title };
}

export default function ArticlePreview({
  className = '', data, first, onClick
}) {

  return (
    <div
      className={`article-preview ${first ? "first" : ""} ${className}`}
      onClick={onClick}
    >
      <img src={data.picture} className="bg" alt="bg" />
      <div className="article-info shadow-object">
        <h1 dangerouslySetInnerHTML={innerFunc(data)}></h1>
        <div className="sub-info property-info">
          <div className="category">{data.topic.name}</div>
          <div className="last_read">{data.read_time} read</div>
        </div>
        <div className="sub-info detail-info">
          <div className="description">{data.excerpt}</div>
          <div className="read-btn center">
            <ReactSVG src={arrow} />
          </div>
        </div>
      </div>
      <div className="bg-dot custom-bg-dot"></div>
    </div>
  );
}
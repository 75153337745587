import React from 'react';
import './style.scss';

const Steps = [1, 2, 3, 4, 5]

export default function Progress({
  className = '', step, onClick
}) {

  return (
    <div className={`circle-progress center ${className}`}>
      <div className='progress-content mx-auto'>
        {Steps.map((i, index) => (
          <div
            key={index}
            className={
              `num-content center 
              ${i < step ? `past` : step === i ? 'current' : 'after'}`
            }
          >
            {i}
          </div>
        ))}
      </div>
    </div>
  );
}
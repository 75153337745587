import { GET_BLOGS, GET_BLOGS_SUCCESS, GET_BLOGS_FAIL, GET_SUBJECT_BLOGS_SUCCESS, GET_SUBJECT_BLOGS_FAIL, GET_SUBJECT_BLOGS } from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import { getBlogs as getBlogsApi } from 'src/Api';

function* getBlogs() {
  try {
    const result = yield getBlogsApi();
    if (result.data) {
      yield put({ type: GET_BLOGS_SUCCESS, data: result.data });
    } else {
      yield put({ type: GET_BLOGS_FAIL, data: [] });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetBlogs() {
  yield takeLatest(GET_BLOGS, getBlogs);
}

function* getSubjectBlogs(payload) {
  try {
    const result = yield getBlogsApi(payload.subject_id);
    console.log(result, result.data)
    if (result.data) {
      yield put({ type: GET_SUBJECT_BLOGS_SUCCESS, data: result.data });
    } else {
      yield put({ type: GET_SUBJECT_BLOGS_FAIL, data: [] });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetSubjectBlogs() {
  yield takeLatest(GET_SUBJECT_BLOGS, getSubjectBlogs);
}

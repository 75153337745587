import React, { useState } from "react";
import './style.scss';
import arrowDown from 'src/Assets/Images/Landing/Arrow-down.png';
import { useNavigate } from "react-router-dom";

export default function SubjectAccordion({page, className}) {
    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false)

    const toggleAccordion = () => setIsOpen(!isOpen)

    const onNavigate = () => navigate(`/subject/${page.subject.name.replace(/\s+/g, '-')}/${page.id}/#`)

    return (
        <div className={`subject-accordion ${isOpen ? 'open': ''} ${className}`}>
            <div className="header" onClick={toggleAccordion}>
                <div className="title">{page.subject.name}</div>
                <img src={arrowDown} className="arrow-down"/>
            </div>
            <div className={`content ${isOpen ? 'show' : ''}`}>
                <p dangerouslySetInnerHTML={{ __html: page.description }}></p>
                <div className="book-btn" onClick={() => onNavigate()}>BOOK TRIAL LESSON FOR $1</div>
            </div>
        </div>
    )
}
import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAIL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  STUDENT_SIGN_UP,
  STUDENT_SIGN_UP_FAIL,
  STUDENT_SIGN_UP_SUCCESS,
  TUTOR_SIGN_UP,
  TUTOR_SIGN_UP_FAIL,
  TUTOR_SIGN_UP_SUCCESS,
  PROFILE_UPDATE,
  PROFILE_UPDATE_FAIL,
  PROFILE_UPDATE_SUCCESS,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
  signIn as signInApi,
  forgotPassword as forgotPasswordApi,
  studentSignUp as studentSignUPApi,
  tutorSignUp as tutorSignUPApi,
  profileUpdate as profileUpdateApi,
} from 'src/Api';

/**
 * Login
*/
function* signIn(payload) {
  try {
    const result = yield signInApi(payload.data);
    console.log("result", result)
    if (result && result.token) {
      yield put({ type: SIGN_IN_SUCCESS, data: result });
    } else {
      yield put({ type: SIGN_IN_FAIL, data: result });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchSignIn() {
  yield takeLatest(SIGN_IN, signIn);
}

/**
 * Forgot Password
*/
function* forgotPassword(payload) {
  try {
    const result = yield forgotPasswordApi(payload.data);
    console.log("result", result)
    if (result) {
      yield put({ type: FORGOT_PASSWORD_SUCCESS, data: result });
    } else {
      yield put({ type: FORGOT_PASSWORD_FAIL, data: result });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD, forgotPassword);
}

/**
 * Student Sign Up
*/
function* studentSignUP(payload) {
  try {
    const result = yield studentSignUPApi(payload.data);
    console.log("result", result)
    if (result && result.token) {
      yield put({ type: STUDENT_SIGN_UP_SUCCESS, data: result });
    } else {
      yield put({ type: STUDENT_SIGN_UP_FAIL, data: result });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchStudentSignUP() {
  yield takeLatest(STUDENT_SIGN_UP, studentSignUP);
}

/**
 * Tutor Sign Up
*/
function* tutorSignUP(payload) {
  try {
    const result = yield tutorSignUPApi(payload.data);
    console.log("result", result)
    if (result) {
      yield put({ type: TUTOR_SIGN_UP_SUCCESS, data: result });
    } else {
      yield put({ type: TUTOR_SIGN_UP_FAIL, data: result });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchTutorSignUP() {
  yield takeLatest(TUTOR_SIGN_UP, tutorSignUP);
}

/**
 * Profile Update
*/
function* profileUpdate(payload) {
  try {
    const result = yield profileUpdateApi(payload.data);
    if (result && result.id) {
      yield put({ type: PROFILE_UPDATE_SUCCESS, data: result });
    } else {
      yield put({ type: PROFILE_UPDATE_FAIL, data: result });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchProfileUpdate() {
  yield takeLatest(PROFILE_UPDATE, profileUpdate);
}

import {
  GET_BRAINTREE_KEY,
  GET_BRAINTREE_KEY_SUCCESS,
  GET_BRAINTREE_KEY_FAIL,
  NONCE_UPDATE,
  NONCE_UPDATE_SUCCESS,
  NONCE_UPDATE_FAIL,
  FIRST_CHARGE,
  FIRST_CHARGE_SUCCESS,
  FIRST_CHARGE_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
  getBraintreeKey as getBraintreeKeyApi,
  nonceUpdate as nonceUpdateApi,
  firstCharge as firstChargeApi,
} from 'src/Api';

/**
* Get Braintreee Key
*/
function* getBraintreeKey() {
  try {
    const result = yield getBraintreeKeyApi();
    if (result.token) {
      yield put({ type: GET_BRAINTREE_KEY_SUCCESS, data: result });
    } else {
      yield put({ type: GET_BRAINTREE_KEY_FAIL, data: result });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetBraintreeKey() {
  yield takeLatest(GET_BRAINTREE_KEY, getBraintreeKey);
}

/**
 * Update Nonce
*/
function* nonceUpdate(payload) {
  try {
    const result = yield nonceUpdateApi(payload.data);
    if (!result.error) {
      yield put({ type: NONCE_UPDATE_SUCCESS, data: result });
    } else {
      yield put({ type: NONCE_UPDATE_FAIL, data: result });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchNonceUpdate() {
  yield takeLatest(NONCE_UPDATE, nonceUpdate);
}

/**
 * First Charge
*/
function* firstCharge() {
  try {
    const result = yield firstChargeApi();
    console.log("===result===", result)
    if (result && !result.error) {
      yield put({ type: FIRST_CHARGE_SUCCESS, data: result });
    } else {
      yield put({ type: FIRST_CHARGE_FAIL, data: result });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchFirstCharge() {
  yield takeLatest(FIRST_CHARGE, firstCharge);
}

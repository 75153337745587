import React, { useEffect, useState } from "react";
import './style.scss';
import facebook from 'src/Assets/Images/Landing/facebook.png';
import twitter from 'src/Assets/Images/Landing/twitter.png';
import linkedin from 'src/Assets/Images/Landing/linkedin.png';
import instagram from 'src/Assets/Images/Landing/instagram.png';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from 'lodash';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";

export default function ContentTable({page}) {
    const params = useParams()
    const location = useLocation()
    const [transform, setTransform] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);
    
    const handleScroll = () => {
        setTransform(window.scrollY > 1000 ? 'transform' : '')
    }

    const onScrollTo = (secId) => window.location.href = `${window.location.origin}/subject/${params.subject}/${params.id}/#${secId}`

    const subject = params.subject.replaceAll("-", " ")

    const ShareUrl = `https://onlinemathtutor.com${location.pathname}`;

    return (
       <div className={`content-table ${transform}`}>
            <h6>Content Table</h6>
            <div className="item" onClick={() => onScrollTo('sub-page-1')}>24/7 {subject} Tutoring</div>
            <div className="item" onClick={() => onScrollTo('sub-page-2')}>Qualifications</div>
            <div className="item" onClick={() => onScrollTo('sub-page-3')}>How It Works</div>
            <div className="item" onClick={() => onScrollTo('sub-page-4')}>Key {subject} Concepts</div>
            <div className="item" onClick={() => onScrollTo('sub-page-5')}>Online Tutoring Examples</div>
            <div className="item" onClick={() => onScrollTo('sub-page-6')}>Tutor Profiles</div>
            <div className="item" onClick={() => onScrollTo('sub-page-7')}>Commonly Asked Questions</div>
            {/* <div className="item" onClick={() => onScrollTo('sub-page-8')}>Other Calculus Subjects We Tutor</div> */}
            <div className="item" onClick={() => onScrollTo('section-pricing')}>Pricing</div>
            <div className="item" onClick={() => onScrollTo('sub-page-0')}>All Services</div>
            <div className="book-btn" onClick={() => navigate('/sign-up/step-1')}>BOOK TRIAL LESSION FOR $1</div>
            <div className="social">Share on social media</div>
            {
                page && (
                    <>
                        <div className="socials">
                            <FacebookShareButton 
                                url={ShareUrl}
                                quote={page.subject_title}
                                className="share-logo">
                                <img src={facebook} alt="facebook" />
                            </FacebookShareButton>
                            {/* <InstapaperShareButton 
                                url={ShareUrl}
                                quote={page.subject_title}
                                className="share-logo">
                                <img src={instagram} alt="instagram" />
                            </InstapaperShareButton> */}
                            <TwitterShareButton 
                                url={ShareUrl} 
                                title={page.subject_title}
                                description={page.description}
                                className="share-logo">
                                <img src={twitter} alt="twitter" />
                            </TwitterShareButton>
                            <LinkedinShareButton 
                                url={ShareUrl}
                                title={page.subject_title}
                                summary={page.description}
                                className="share-logo">
                                <img src={linkedin} alt="linkedin" />
                            </LinkedinShareButton>
                        </div>
                    </>
                )
            }
       </div>
    )
}